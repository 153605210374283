<template>
  <div class="content">
    <h2>Arthritic pain</h2>
    <hr />
    <h4>Introduction:</h4>
    The word arthritis literally means joint inflammation, but it is often used
    to refer to a group of more than 100 rheumatic diseases that can cause pain,
    stiffness, and swelling in the joints. These diseases may affect not only
    the joints but also other parts of the body, including supporting structures
    such as muscles, bones, tendons, and ligaments, as well as some internal
    organs. This article focuses on pain caused by two of the most common forms
    of arthritis - osteoarthritis and rheumatoid arthritis. <br />Most forms of
    arthritis are associated with pain that can be divided into two general
    categories: acute and chronic. Acute pain is temporary. It can last a few
    seconds or longer but wanes as healing occurs. Some examples of things that
    cause acute pain include burns, cuts, and fractures. Chronic pain, such as
    that seen in people with osteoarthritis and rheumatoid arthritis, ranges
    from mild to severe and can last weeks, months, and years to a lifetime.
    <br />Chronic pain is a major health problem in the United States and is one
    of the most weakening effects of arthritis. More than 40 million Americans
    are affected by some form of arthritis, and many have chronic pain that
    limits daily activity. Osteoarthritis is by far the most common form of
    arthritis, affecting over 20 million Americans, while rheumatoid arthritis,
    which affects about 2.1 million Americans, is the most disabling form of the
    disease.

    <h4>Causes:</h4>
    The pain of arthritis may come from different sources. These may include
    inflammation of the synovial membrane (tissue that lines the joints), the
    tendons, or the ligaments; muscle strain; and fatigue. A combination of
    these factors contributes to the intensity of the pain. <br />The pain of
    arthritis varies greatly from person to person, for reasons that doctors do
    not yet understand completely. Factors that contribute to the pain include
    swelling within the joint, the amount of heat or redness present, or damage
    that has occurred within the joint. In addition, activities affect pain
    differently so that some patients note pain in their joints after first
    getting out of bed in the morning, whereas others develop pain after
    prolonged use of the joint. <br />Each individual has a different threshold
    and tolerance for pain, often affected by both physical and emotional
    factors. These can include depression, anxiety, and even hypersensitivity at
    the affected sites due to inflammation and tissue injury. This increased
    sensitivity appears to affect the amount of pain perceived by the
    individual. Social support networks can make an important contribution to
    pain management.

    <h4>Treatment:</h4>
    There is no single treatment that applies to everyone with arthritis, but
    rather the doctor will develop a management plan designed to minimize your
    specific pain and improve the function of your joints. A number of
    treatments can provide short-term pain relief.

    <h5>Short-Term Relief</h5>
    <ul>
      <li>
        <h5>Medications</h5>
        Because people with osteoarthritis have very little inflammation, pain
        relievers such as acetaminophen (e.g., Tylenol) may be effective.
        Patients with rheumatoid arthritis generally have pain caused by
        inflammation and often benefit from aspirin or other nonsteroidal
        anti-inflammatory drugs (NSAIDs) such as ibuprofen (e.g., Motrin,
        Advil).
      </li>
      <li>
        <h5>Heat and cold</h5>
        The decision to use either heat or cold for arthritis pain depends on
        the type of arthritis and should be discussed with your doctor or
        physical therapist. Moist heat, such as a warm bath or shower, or dry
        heat, such as a heating pad, placed on the painful area of the joint for
        about 15 minutes may relieve the pain. An ice pack (or a bag of frozen
        vegetables) wrapped in a towel and placed on the sore area for about 15
        minutes may help to reduce swelling and stop the pain. If you have poor
        circulation, do not use cold packs.
      </li>
      <li>
        <h5>Joint protection</h5>
        Using a splint or a brace to allow joints to rest and protect them from
        injury can be helpful. Your physician or physical therapist can make
        recommendations.
      </li>
      <li>
        <h5>Transcutaneous electrical nerve stimulation (TENS)</h5>
        A small TENS device that directs mild electric pulses to nerve endings
        that lie beneath the skin in the painful area may relieve some arthritis
        pain. TENS seems to work by blocking pain messages to the brain and by
        modifying pain perception.
      </li>
      <li>
        <h5>Massage</h5>
        In this pain-relief approach, a massage therapist will lightly stroke
        and/or knead the painful muscle. This may increase blood flow and bring
        warmth to a stressed area. However, arthritis-stressed joints are very
        sensitive, so the therapist must be familiar with the problems of the
        disease.
      </li>
    </ul>
    <h5>Long-Term Relief</h5>
    <ul>
      <li>
        <h5>Weight reduction</h5>
        Excess pounds put extra stress on weight-bearing joints such as the
        knees or hips. Studies have shown that overweight women who lost an
        average of 11 pounds substantially reduced the development of
        osteoarthritis in their knees. In addition, if osteoarthritis has
        already affected one knee, weight reduction will reduce the chance of it
        occurring in the other knee.
      </li>
      <li>
        <h5>Exercise</h5>
        Swimming, walking, low-impact aerobic exercise, and range-of-motion
        exercises may reduce joint pain and stiffness. In addition, stretching
        exercises are helpful. A physical therapist can help plan an exercise
        program that will give you the most benefit.
      </li>
      <li>
        <h5>Surgery</h5>
        In select patients with arthritis, surgery may be necessary. The surgeon
        may perform an operation to remove the synovium (synovectomy), realign
        the joint (osteotomy), or in advanced cases replace the damaged joint
        with an artificial one (arthroplasty). Total joint replacement has
        provided not only dramatic relief from pain but also improvement in
        motion for many people with arthritis.
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "ArthriticPain"
};
</script>

<style scoped></style>
