<template>
  <div class="content">
    <h2>Acute herpetic and postherpetic neuralgia</h2>
    <hr />
    <h4>Introduction:</h4>
    The medical term for shingles is <b>acute herpes zoster</b>. Shingles is a
    skin rash that develops on half of the body, in a belt-like pattern. The
    rash is usually on either the right or left side of the chest, starting in
    the middle of the back and wrapping around to the breast -- but it can occur
    on any part of the body, such as the forehead and abdomen. <br />Most of the
    time, shingles is very painful. Sometimes the pain from shingles starts
    several days before the rash appears. When the pain starts before the skin
    rash, it can be very hard for doctors to make the correct diagnosis. Many
    patients have been told they have heart attacks, appendicitis, and migraine
    headaches before getting the correct diagnosis of shingles. <br /><b
      >Postherpetic neuralgia (PHN)</b
    >
    is the most common complication of herpes zoster or shingles. It is defined
    broadly as any pain that remains after healing of herpes zoster lesions or
    rash, usually after a three month period. <br />This acute phase lasts until
    the lesions are healed, usually several weeks after the onset of the rash.
    Postherpetic neuralgia (PHN) refers to pain that persists after the acute
    phase of the illness passes. The exact point at which acute herpes becomes
    PHN is arbitrary. <br />Fortunately, in most cases the pain of shingles
    gradually disappears over several weeks or months. Most people with shingles
    will have no pain or just a little pain one year after the rash.

    <h4>Causes:</h4>
    Many people get chicken pox when they are children or even when they get
    older. Chicken pox is caused by the <b>varicella zoster virus</b>, a herpes
    type of virus. After the chicken pox heals, the varicella zoster virus moves
    from the skin along the nerves and into an area called the
    <b>dorsal root ganglia</b>, a part of the nerves which lie next to the
    spinal cord. The virus stays there for many years in an inactive state.
    <br />The virus is usually inactive for decades. It can "wake up," become
    active again and multiply when a person's immune system becomes weakened.
    For most people who get shingles, the weakening of the immune system is not
    the result of a serious problem. It is true that shingles may be brought on
    by cancer, AIDS, or drugs that lower the immune system, but this happens in
    a very small group of patients. The most common reason for lowered immunity
    in shingles patients is being elderly and experiencing a stressful event,
    such as an illness in the family or emotional distress. <br />The
    reactivated virus begins to multiply within the dorsal root ganglia, which
    causes damage and swelling to this area of the nerve. This damage to the
    nerve causes the first pains of shingles. The virus then moves along the
    nerve to the skin, damaging the nerve and causing swelling as it goes. When
    the virus finally reaches the skin, it causes the shingles rash.

    <h4>Treatment:</h4>
    <ul>
      <li>
        <h5>
          Antiviral Medication (such as acyclovir, valacyclovir, and
          famciclovir)
        </h5>
        For most patients with shingles, oral antiviral medication should be
        prescribed for 7 days. The earlier this medication is taken, the better
        the chance of stopping the virus from causing more damage to the nerves.
        Early treatment with antiviral medication can lessen the intensity and
        duration of shingles pain (but, as mentioned above, there is no definite
        proof that these medications will stop the patient from getting PHN).
        Once the rash has healed, the patient should stop taking antiviral
        medication.
      </li>
      <li>
        <h5>Opioid Medication (narcotics)</h5>
        Opioid medications, such as morphine, oxycodone, codeine, hydromorphone,
        and methadone, can provide good pain relief without side effects for
        many patients. In most cases, there should be no concern about
        developing "addiction" when these drugs are used to treat the severe
        pain of shingles. Patients can be safely taken off of the narcotic
        medication if it is no longer needed.
      </li>
      <li>
        <h5>Nerve Blocks</h5>
        Pain specialists can inject numbing medications (called
        <b>local anesthetics</b>) directly into certain nerves to help
        withshingles pain. As mentioned above, these nerve blocks have not been
        proven to reduce the chances of developing PHN, but nerve blocks may
        provide good temporary pain relief for the shingles.
      </li>
      <li>
        <h5>Steroids (such as prednisone)</h5>
        Some studies have shown that early treatment with a short course
        (usually 1-2 weeks) of steroids can decrease the intensity and duration
        of pain associated with acute shingles.
      </li>
    </ul>
    <h5>For Postherpetic Neuralgia</h5>
    <ul>
      <li>
        <h5>Non-drug therapies</h5>
        Like other kinds of patients with chronic pain, patients with PHN may
        benefit from many non-drug treatments. These include rehabilitation
        therapies and psychological therapies (such as relaxation therapy and
        biofeedback). Also, patients may get pain relief from therapies that
        stimulate the nerves, such as TENS (Trancutaneous Electrical Nerve
        Stimulation). TENS units are small devices that send very small amounts
        of electrical current to the skin through electrodes that are stuck to
        the skin.
      </li>
      <li>
        <h5>Opioids (such as oxycodone, morphine, methadone)</h5>
        For some patients, opioid medications greatly relieve the pain of PHN
        without serious side effects. When using these drugs as the main pain
        medication, it is important that the doses be given "around-the-clock"
        to keep a certain amount of the drug in the bloodstream.
      </li>
      <li>
        <h5>
          Tricyclic Antidepressants (such as amitryptiline [Elavil],
          nortriptyline [Pamelor], desipramine, doxepin)
        </h5>
        Up until the past year or so, tricyclic antidepressants (TCAs) were
        probably the most widely used medicines to treat the pain of PHN. Many
        studies have shown that some patients with PHN have good pain relief
        from these drugs. However, many patients also complain of side effects,
        such as severe dry mouth, constipation, sedation, trouble thinking, and
        dizziness.
      </li>
      <li>
        <h5>Topical Lidocaine Patch</h5>
        This is a new medication that can be used to treat the pain of PHN and
        other conditions. As many as 3 patches (each about the size of an adult
        hand) can be placed directly over the painful area of the skin. The
        patches are applied for at least 12 hours on the skin. They may be
        removed from the skin for 12 hours per day. The medication is believed
        to act locally. Within 1-2 weeks, most patients begin to notice relief.
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "HerpeticNeuralgia"
};
</script>

<style scoped></style>
