<template>
  <div>
    <h1>We couldn't find the page you're looking for :(</h1>
  </div>
</template>

<script>
export default {
  name: "Error404"
};
</script>

<style scoped></style>
