import { render, staticRenderFns } from "./Discography.vue?vue&type=template&id=314a8245&scoped=true"
import script from "./Discography.vue?vue&type=script&lang=js"
export * from "./Discography.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "314a8245",
  null
  
)

export default component.exports