<template>
  <div class="content">
    <h2>About Us</h2>
    <hr />
    Pain Medics helps support acute and chronic pain patients in their efforts
    to improve quality of life, increase daily activities and restore functions.
    We are an appointment only, referral based practice. A referral from a
    Family Doctor or other specialist is needed.
    <br />
    <br />
    <h2>We provide</h2>
    <hr />
    <ul>
      <li>Consultation and management services for complex pain problems.</li>
      <li>Diagnostic and therapeutic interventional pain modalities.</li>
      <li>
        Referrals for physical therapy, cognitive behavioural therapy, etc
        provided as appropriate.
      </li>
      <li>Prompt and timely evaluation and feedback to referring physician.</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Home"
};
</script>

<style scoped>
.content {
  font-size: 1.25vw;
}
</style>
