<template>
  <div class="content">
    <h2>Neck/shoulder pain of various etiologies</h2>
    <hr />
    <h4>Introduction:</h4>
    Your neck and shoulders contain muscles, bones, nerves, arteries, and veins,
    as well as many ligaments and other supporting structures. Many conditions
    can cause pain in the neck and shoulder area. Some are life threatening
    (such as heart attack and major trauma), and others are not so dangerous
    (such as simple strains or contusions).

    <h4>Causes:</h4>
    Most shoulder and neck pain results from injury to muscles and ligaments.
    The spinal cord, heart, lungs, and some abdominal organs also can cause neck
    and shoulder pain. Here are some examples:
    <ul>
      <li>
        <h5>Broken collarbone</h5>
        Falling on your outstretched arm can cause your collarbone to break.
      </li>
      <li>
        <h5>Heart attacks</h5>
        Although the problem is the heart, heart attacks can cause shoulder or
        neck pain, known as <b>"referred"</b>
        pain.
      </li>
      <li>
        <h5>Shoulder or A-C separation</h5>
        The collarbone and shoulder blade are connected by a ligament. With
        trauma to the shoulder, this ligament can be stretched or torn.
      </li>
      <li>
        <h5>Broken shoulder blade</h5>
        An injury to the shoulder blade usually is associated with relatively
        forceful trauma.
      </li>
      <li>
        <h5>Gallbladder disease</h5>
        This can cause a pain <b>"referred"</b>
        to the right shoulder.
      </li>
      <li>
        <h5>Whiplash injury</h5>
        Injury to the ligamentous and muscular structures of the neck and
        shoulder can be caused by sudden acceleration or deceleration, as in a
        car accident.
      </li>
    </ul>
    <h4>Treatment:</h4>
    Minor injuries that have only slight pain can be treated at home. If the
    source of the pain and the cause of the pain are not known, or if symptoms
    suggest you might have a more serious condition, you should contact your
    doctor while initiating basic care measures.

    <ul>
      <li>
        <h5>Heat</h5>
        Do not use heat in the first week because it can increase the swelling
        in the injured area and worsen your pain.
      </li>
      <li>
        <h5>Pain control</h5>
        Acetaminophen (Tylenol) or ibuprofen (Advil, Motrin) can help control
        swelling and pain.
      </li>
      <li>
        <h5>Rest</h5>
        Use the injured area as little as possible for the first 2-3 days, then
        slowly begin to exercise the injured area. This speeds recovery.
      </li>
      <li>
        <h5>Ice</h5>
        Place the ice in a plastic bag, wrap the bag with a towel, and then
        apply to the injured area for 15-20 minutes every hour. Directly
        applying ice can damage the skin.
      </li>
      <li>
        <h5>Elevation</h5>
        Elevation of the injured area above your heart helps the swelling go
        down. This reduces your pain. Use pillows to prop yourself up.
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "NeckShoulderPain"
};
</script>

<style scoped></style>
