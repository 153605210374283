<template>
  <div class="content">
    <h2>Phantom limb pain/post-amputation pain/stump pain</h2>
    <hr />
    <h4>Introduction:</h4>
    Phantom limb pain is pain felt in the area where a limb has been amputated.
    Phantom limb pain can be mild to extremely painful. In some cases, phantom
    limb pain can be disabling and can lead to a lifelong struggle with chronic
    pain. Phantom limb sensations usually will disappear or decrease over time.
    When phantom limb pain continues for more than six months, the prognosis for
    spontaneous improvement is poor. <br />In addition to pain in the phantom
    limb, some people experience other sensations such as tingling, cramping,
    heat, and cold in the portion of the limb that was removed. Any sensation
    that the limb could have experienced prior to the amputation might be
    experienced in the amputated phantom limb.

    <h4>Causes:</h4>
    Although the limb is no longer there, the nerve endings at the site of the
    amputation continue to send pain signals to the brain that make the brain
    think the limb is still there. Sometimes, the brain memory of pain is
    retained and is interpreted as pain regardless of signals from injured
    nerves.

    <h4>Treatment:</h4>
    Successful treatment of phantom limb pain is difficult. Treatment is usually
    based on the amount of pain the patient is feeling, and multiple treatments
    might be combined. Some treatments include:
    <ul>
      <li>Heat application</li>
      <li>
        Neurostimulation techniques such as spinal cord stimulation or deep
        brain stimulation
      </li>
      <li>Transcutaneous electrical nerve stimulation (TENS) of the stump</li>
      <li>
        Physical therapy (usually, the best approach is to combine multiple
        treatments)
      </li>
      <li>Surgery to remove scar tissue entangling a nerve</li>
      <li>Massage of the amputation area</li>
      <li>Relaxation techniques</li>
      <li>Biofeedback to reduce muscle tension</li>
    </ul>
    In some instances, medicines might need to be administered in combination
    for optimal pain control. Medicines that are used to diminish and control
    phantom pain, include:
    <ul>
      <li>Analgesics</li>
      <li>Neuroleptics</li>
      <li>Anticonvulsants</li>
      <li>Tricyclic antidepressants</li>
      <li>Beta-blockers</li>
      <li>Sodium channel blockers</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "AmputationPain"
};
</script>

<style scoped></style>
