<template>
  <div class="content">
    <h2>Cervicogenic headaches</h2>
    <hr />
    <h4>Introduction:</h4>
    Cervicogenic headaches refer to headaches which originate from tissues and
    structures in the cervical spine or neck region. The headache is generally a
    very constant, strong, yet dull pain. The most common location of pain is
    around the orbital (eye) region and upper neck area but may also include
    other areas of the face, head and neck. The headache will typically last for
    one to three days and reoccur ever one to four weeks until properly treated.
    The headache may also be accompanied by nausea, vomiting, dizziness, ringing
    of the ears, and sensitivity to light and sound - similar to migraine
    headaches. <br />Cervicogenic headaches are caused by irritation or injury
    to the structures of the upper neck region, resulting in local neck pain as
    well as referred pain to the temporal and facial regions. This headache is
    often precipitated or aggravated by head and neck movements and by applying
    deep pressure to the muscles of the upper cervical area.

    <h4>Causes:</h4>
    Cervicogenic headaches are caused by irritation or injury to the structures
    of the upper neck region, resulting in local neck pain as well as referred
    pain to the temporal and facial regions. This headache is often precipitated
    or aggravated by head and neck movements and by applying deep pressure to
    the muscles of the upper cervical area. <br />Nerves in the neck converge in
    the trigeminal nerve. This is the largest nerve in the skull. It originates
    in the brain stem and supplies sensation to the face. This nerve can
    generate pain signals to the facial area that the brain may interpret as
    headache. Pain is usually on one side; even if it affects both sides of the
    head it is usually more severe on one side. The quality of the headache may
    be difficult to distinguish from an aching tension headache or a mild
    migraine without aura. Cervicogenic headaches can result from prolonged poor
    posture (such as that caused by sitting in front of a computer keyboard or
    driving daily for long periods), arthritis, injuries of the upper spine, or
    abnormalities in the cervical spine (the spinal bones in the neck).

    <h4>Treatment:</h4>
    The treatment for a cervicogenic headache, involves a comprehensive
    evaluation of the possible causes and contributing factors. This involves a
    physical therapy, and an expert spine therapist should be referred for the
    treatment. <br />There are certain injections which are effective in these
    headaches. The most effective injections are x-ray guided facet joint
    blocks, especially of the upper facet levels. Caution! These should
    absolutely only be performed by a physician who is well trained, skilled,
    and experienced in such procedures, and if the investigation leads to the
    discovery of anything significant, then further treatment will be taken from
    there. <br />Chiropractic management of cervicogenic headaches is the best
    way to eliminate these headaches. Without addressing the problems in
    cervical spine the headache will continue to persist and worsen. Individuals
    should be warned that relying on analgesics to remedy cervicogenic headaches
    does nothing to correct the cause of the headache and generally worsens the
    headache in what's known as the "rebound effect".
  </div>
</template>

<script>
export default {
  name: "CervicogenicHeadaches"
};
</script>

<style scoped></style>
