<template>
  <div class="content">
    <h2>Lumbar sympathetic block</h2>
    <hr />
    <h4>What is it?</h4>
    A <b>lumbar sympathetic block</b> is an injection of local anesthetic in the
    <b>sympathetic nerve tissue</b> (the nerves which are a part of the
    Sympathetic Nervous System). The nerves are located on either side of the
    spine in the back.

    <h4>Why is it performed?</h4>
    The injection blocks the Sympathetic Nerves. This may in turn reduce pain,
    swelling, color, and sweating changes in the lower extremity and may improve
    mobility. It is done as a part of the treatment of
    <b>Reflex Sympathetic Dystrophy</b> (RSD),
    <b>Sympathetic Maintained Pain</b>, <b>Complex Regional Pain Syndrome</b>,
    and <b>Herpes Zoster</b> (shingles) involving the lower extremity.

    <h4>How is it done?</h4>
    It is done with the patient lying on their stomach. The patients are
    monitored with EKG, blood pressure cuff and blood oxygen-monitoring device.
    Temperature sensing probes are also placed on your feet. The skin on the
    back is cleaned with antiseptic solution and then the injection is carried
    out. <b>Fluoroscopy</b> (x-rays) is used to guide the needle(s) to the
    proper position.
  </div>
</template>

<script>
export default {
  name: "LumbarBlock"
};
</script>

<style scoped></style>
