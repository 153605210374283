<template>
  <div class="content-wrapper">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "ContentWrapper",
  components: {}
};
</script>

<style scoped>
.content-wrapper {
  width: 65%;
  text-align: left;
  padding: 2%;
  border-radius: 0.25em;
  /* box-shadow: 0 0.2em 0.2em 0 rgba(0, 0, 0, 0.103), 0 0.2em 2em 0 rgba(0, 0, 0, 0.19); */
  float: left;
}

/deep/ hr {
  display: block;
  height: 1.75px;
  border: 0;
  background-image: linear-gradient(to right, #039be5, transparent);
  margin: 1em 0;
  padding: 0;
}

/deep/ h2 {
  font-size: 2vw;
}

/deep/ ul {
  margin-left: 0.25vw;
}
</style>
