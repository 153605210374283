<template>
  <div class="content">
    <h2>Discography</h2>
    <hr />
    <h4>What is it?</h4>
    Discography is a diagnostic procedure in which x-ray dye (x-ray contrast) is
    injected into the discs of the spine. After the x-ray dye is injected, an
    x-ray (called a <b>discogram</b>) is taken of the discs. The discogram may
    be normal or may show tears (fissures) in the lining of the disc. The
    results of discography are used to plan surgery or IDET (intradiscal
    electrothermal) treatment.

    <h4>Why is it performed?</h4>
    Discography is done to answer the questions:
    <ol>
      <li>"Is my back pain or neck pain from a degenerated disc?"</li>
      <li>"Which discs - if any - are causing my pain?"</li>
    </ol>
    Discography helps identify the specific discs contributing to the pain. The
    results are then used to try more advanced treatment options.

    <h4>How is it done?</h4>
    The procedure is done in the Operating Room with fluoroscopic (x-ray)
    guidance. For lumbar discography (discs in the low back), it is done with
    you lying on your stomach. For cervical discography (discs in the neck), it
    is usually done with you lying on your back. <br />There will be an
    anesthesiologist or a nurse present during the procedure to monitor you and
    administer intravenous sedation to help you be comfortable and relaxed. You
    are watched closely with an EKG monitor, blood pressure cuff and blood
    oxygen-monitoring device. The skin over the injection site(s) is cleaned
    with an antiseptic solution and then the injections are carried out. After
    the injection, you are placed on your back or on your side.
  </div>
</template>

<script>
export default {
  name: "Discography"
};
</script>

<style scoped></style>
