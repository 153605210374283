<template>
  <div class="content">
    <h2>Spinal cord stimulation</h2>
    <hr />
    <h4>What is it?</h4>
    A spinal cord stimulator (SCS) is a device which delivers low voltage
    electrical stimulation to the spinal cord. The electrical stimulation to the
    spinal cord is delivered through one or two wires which are carefully placed
    in the <b>epidural space</b> (a space adjacent to the spinal cord).

    <h4>Why is it performed?</h4>
    The goal of the implantation of a spinal cord stimulator is to
    <b>reduce</b> rather than eliminate pain. It might reduce the pain by 50
    percent or more, it could increase the activity level, and it might reduce
    the use of narcotic medications.

    <h4>How is it done?</h4>
    The spinal cord stimulator is implanted in two stages. The first stage is a
    trial and it is performed in the operating room with local anesthetic and
    intravenous sedation. In this stage, we place the wires through the skin,
    into the epidural space, which is adjacent to the spinal cord. Then the
    wires are connected to a stimulating generator. After 24 hours observation
    in the hospital the patient is discharged to home. We leave the wires in
    place for a week. This is really a test to determine if a permanent
    stimulating generator should be used. If the trial is successful, we place a
    permanent generator under the skin. The generator contains a battery. When
    the battery runs down, it is replaced. Placement of the generator under the
    skin is done in the operating room and after the procedure, the patient goes
    home. General anesthesia is not needed for the implantation of a spinal cord
    stimulator.
  </div>
</template>

<script>
export default {
  name: "SpinalStimulation"
};
</script>

<style scoped></style>
