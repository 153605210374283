<template>
  <div class="clearfix">
    <div class="vertical-spacer"></div>
    <router-view />
    <right-sidebar />
  </div>
</template>

<script>
import RightSidebar from "@/components/RightSidebar";

export default {
  name: "PageBody",
  components: {
    RightSidebar
  }
};
</script>

<style scoped>
.clearfix {
  width: 80%;
  float: right;
}

.clearfix:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

html[xmlns] .clearfix {
  display: block;
}

* html .clearfix {
  height: 1%;
}

.vertical-spacer {
  width: 2.5%;
  display: block;
  height: 2em;
  float: left;
}

/deep/ a:link {
  text-decoration: none;
  color: #2c3e50;
  border-bottom: 1px solid #2c3e50;
  transition: 0.3s;
}

/deep/ a:visited {
  text-decoration: none;
  color: #006157;
  border-bottom: 1px solid #006157;
}

/deep/ a:hover {
  text-decoration: none;
  border-bottom: 1px solid #039be5;
  font-size: 110%;
}

/deep/ a:active {
  color: #039be5;
}
</style>
