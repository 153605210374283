<template>
  <div class="content">
    <h2>Stellate ganglion block</h2>
    <hr />
    <h4>What is it?</h4>
    A stellate ganglion block is useful for treating and/or diagnosing a number
    of painul conditions. It is the injection of local anesthetic into the area
    of a specialized nerve structure. The injection blocks sympathetic nerves.
    If these nerves are responsible for transmission of pain, the pain will be
    reduced after the injection. We perform this procedure for patients with
    pain in the face, neck, arms and chest.

    <h4>Why is it performed?</h4>
    The injection blocks the <b>Sympathetic Nerves.</b> This may in turn reduce
    pain, swelling, color, and sweating changes in the upper extremity and may
    improve mobility. It is done as a part of the treatment of
    <b>Reflex Sympathetic Dystrophy</b> (RSD),
    <b>Sympathetic Maintained Pain</b>, <b>Complex Regional Pain Syndrome</b>,
    and <b>Herpes Zoster</b> (shingles) involving the upper extremity or head
    and face.

    <h4>How is it done?</h4>
    It is done either with the patient laying flat or slightly sitting up. The
    chin is slightly raised. The patients are monitored with EKG, blood pressure
    cuff and blood oxygen-monitoring device. Temperature-sensing probes are also
    placed on your thumbs or hands. The skin in the front of the neck next to
    the "voice box" is cleaned with antiseptic solution and then the injection
    is carried out.
  </div>
</template>

<script>
export default {
  name: "StellateBlock"
};
</script>

<style scoped></style>
