<template>
  <div class="content">
    <h2>Trigeminal neuralgia</h2>
    <hr />
    <h4>Introduction:</h4>
    Imagine having a jab of lightning-like pain shoot through your face when you
    brush your teeth or put on makeup. Sound excruciating? If you have
    trigeminal neuralgia, attacks of such pain are frequent and can often seem
    unbearable. <br />You may initially experience short, mild attacks, but
    trigeminal neuralgia can progress, causing longer, more frequent bouts of
    searing pain. These painful attacks can be spontaneous, but they may also be
    provoked by even mild stimulation of your face, including brushing your
    teeth, shaving or putting on makeup. The pain of trigeminal neuralgia may
    occur in a fairly small area of your face, or it may spread rapidly over a
    wider area. <br />Because of the variety of treatment options available,
    having trigeminal neuralgia doesn't necessarily mean you're doomed to a life
    of pain. Doctors usually can effectively manage trigeminal neuralgia, either
    with medications or surgery.

    <h4>Causes:</h4>
    The condition is called trigeminal neuralgia because the painful facial
    areas are those served by one or more of the three branches of your
    trigeminal nerve. This large nerve originates deep inside your brain and
    carries sensation from your face to your brain. The pain of trigeminal
    neuralgia is due to a disturbance in the function of the trigeminal nerve.
    Trigeminal neuralgia is also known as <b>tic douloureux</b>. <br />The cause
    of the pain usually is due to contact between a normal artery or vein and
    the trigeminal nerve at the base of your brain. This places pressure on the
    nerve as it enters your brain and causes the nerve to misfire. Physical
    nerve damage or stress may be the initial trigger for trigeminal neuralgia.
    <br />After the trigeminal nerve leaves your brain and travels through your
    skull, it divides into three smaller branches, controlling sensation
    throughout your face:
    <ul>
      <li>
        The first branch controls sensation in your eye, upper eyelid and
        forehead.
      </li>
      <li>
        The second branch controls sensation in your lower eyelid, cheek,
        nostril, upper lip and upper gum.
      </li>
      <li>
        The third branch controls sensations in your jaw, lower lip, lower gum
        and some of the muscles you use for chewing.
      </li>
    </ul>
    You may feel pain in the area served by just one branch of the trigeminal
    nerve, or the pain may affect all branches on one side of your face.
    <br />Besides compression from blood vessel contact, other less frequent
    sources of pain to the trigeminal nerve may include:
    <ul>
      <li>Compression by a tumor</li>
      <li>Multiple sclerosis</li>
      <li>
        A stroke affecting the lower part of your brain, where the trigeminal
        nerve enters your central nervous system
      </li>
    </ul>
    Trigeminal neuralgia affects women more often than men. The disorder is more
    likely to occur in people who are older than 50. About 5 percent of people
    with trigeminal neuralgia have other family members with the disorder, which
    suggests a possible genetic cause in some cases.

    <h4>Treatment:</h4>
    Medications are the usual initial treatment for trigeminal neuralgia.
    Medications are often effective in lessening or blocking the pain signals
    sent to your brain. A number of drugs are available. If you stop responding
    to a particular medication or experience too many side effects, switching to
    another medication may work for you.
    <h5>Medications</h5>
    <ul>
      <li>
        <h5>Carbamazepine (Tegretol, Carbatrol)</h5>
        Carbamazepine, an anticonvulsant drug, is the most common medication
        that doctors use to treat trigeminal neuralgia. In the early stages of
        the disease, carbamazepine controls pain for most people. However, the
        effectiveness of carbamazepine decreases over time. Side effects include
        dizziness, confusion, sleepiness and nausea.
      </li>
      <li>
        <h5>Oxcarbazepine (Trileptal)</h5>
        Oxcarbazepine is another anticonvulsant medication and is similar to
        carbamazepine. Side effects include dizziness and double vision.
      </li>
      <li>
        <h5>Phenytoin (Dilantin, Phenytek)</h5>
        Phenytoin, another anticonvulsant medication, was the first medication
        used to treat trigeminal neuralgia. Side effects include gum
        enlargement, dizziness and drowsiness.
      </li>
      <li>
        <h5>Baclofen</h5>
        Baclofen is a muscle relaxant: Its effectiveness may increase when it is
        used in combination with carbamazepine or phenytoin. Side effects
        include confusion, nausea and drowsiness.
      </li>
    </ul>
    <h5>Surgery</h5>
    The goal of a number of surgical procedures is to either damage or destroy
    the part of the trigeminal nerve that's the source of your pain. Because the
    success of these procedures depends on damaging the nerve, facial numbness
    of varying degree is a common side effect. These procedures involve:
    <ul>
      <li>
        <h5>Radiation</h5>
        Gamma-knife radiosurgery (GKR) involves delivering a focused, high dose
        of radiation to the root of the trigeminal nerve. The radiation damages
        the trigeminal nerve and reduces or eliminates the pain. Relief isn't
        immediate and can take several weeks to begin. GKR is successful in
        eliminating pain more than half of the time. Sometimes the pain may
        recur. The procedure is painless and typically is done without
        anesthesia. Because this procedure is relatively new, the long-term
        risks of this type of radiation are not yet known.
      </li>
      <li>
        <h5>Severing the nerve</h5>
        A procedure called partial sensory rhizotomy (PSR) involves cutting part
        of the trigeminal nerve at the base of your brain. Through an incision
        behind your ear, your doctor makes a quarter-sized hole in your skull to
        access the nerve. This procedure usually is helpful, but almost always
        causes facial numbness. And it's possible for pain to recur. If your
        doctor doesn't find an artery or vein in contact with the trigeminal
        nerve, he or she won't be able to perform an MVD, and a PSR may be done
        instead.
      </li>
      <li>
        <h5>Compression</h5>
        In a procedure called
        <b>percutaneous balloon compression of the trigeminal nerve (PBCTN)</b>,
        your doctor inserts a hollow needle through your face and into an
        opening in the base of your skull. Then, a thin, flexible tube
        (catheter) with a balloon on the end is threaded through the needle. The
        balloon is inflated with enough pressure to damage the nerve and block
        pain signals. PBCTN successfully controls pain in most people, at least
        for a while. Most people undergoing PBCTN experience facial numbness of
        varying degrees, and more than half experience nerve damage resulting in
        a temporary or permanent weakness of the muscles used to chew.
      </li>
      <li>
        <h5>Alcohol injection</h5>
        Alcohol injections under the skin of your face, where the branches of
        the trigeminal nerve leave the bones of your face, may offer temporary
        pain relief by numbing the areas for weeks or months. Because the pain
        relief isn't permanent, you may need repeated injections or a different
        procedure.
      </li>
      <li>
        <h5>Electric current</h5>
        A procedure called
        <b
          >percutaneous stereotactic radiofrequency thermal rhizotomy (PSRTR)</b
        >
        selectively destroys nerve fibers associated with pain.
        <br />Your doctor threads a needle through your face and into an opening
        in your skull. Once in place, an electrode is threaded through the
        needle until it rests against the nerve root. <br />An electric current
        is passed through the tip of the electrode until it's heated to the
        desired temperature. The heated tip damages the nerve fibers and creates
        an area of injury (lesion). If your pain isn't eliminated, your doctor
        may create additional lesions. <br />PSRTR successfully controls pain in
        most people. Facial numbness is a common side effect of this type of
        treatment. The pain may return after a few years.
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "TrigeminalNeuralgia"
};
</script>

<style scoped></style>
