<template>
  <div class="content">
    <h2>Facet joint injections</h2>
    <hr />
    <h4>What is it?</h4>
    Facet Joint Injection is an injection of long lasting steroid ("cortisone")
    in the facet joints which are located in the back area, as a part of the
    bony structure.

    <h4>Why is it performed?</h4>
    Facet joint injections are commonly used to determine what is causing back
    pain. Facet joint injections are primarily <b>diagnostic</b> injections,
    meaning that they help your doctor determine the cause of your back pain but
    may not provide you with any long-term relief from the pain. These
    injections eliminate pain temporarily by filling the facet joint with an
    anesthetic medication that numbs the facet joint, the ligaments, and joint
    capsule around the facet joint. If the facet joint is injected and your pain
    goes away for several hours, then it is very likely that the joint is
    causing your pain. Once you and your doctor know what structure is causing
    your pain, you can begin to explore options for treating the condition.

    <h4>How is it done?</h4>
    It is done either with the patient lying on the stomach, or for the cervical
    (neck area) injections in sitting position, under x-ray control. The
    patients are monitored with EKG, blood pressure cuff and blood
    oxygen-monitoring device. The skin in the back is cleaned with antiseptic
    solution and then the injection is carried out. After the injection, you are
    placed on your back or on your side.
  </div>
</template>

<script>
export default {
  name: "FacetInjections"
};
</script>

<style scoped></style>
