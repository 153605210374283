<template>
  <div class="content">
    <h2>Occipital headaches</h2>
    <hr />
    <h4>Introduction:</h4>
    An occipital headache is usually defined as a sudden jabbing pain in the
    distribution of the greater or lesser occipital nerves. There is often
    accompanied by diminished sensation or tingling in the affected area.
    <br />Differentiating between occipital headache and other forms of headache
    may be difficult secondary to variability in presentation and considerable
    symptom overlap. Pain is usually localized in the distribution of the
    greater or lesser occipital nerves. The affected nerve is tender to
    palpation. Diagnostic injections have a role in not only differentiating
    different types of headaches, but also identifying the pain generator.

    <h4>Causes:</h4>
    There are two types of headaches: <b>primary headaches</b> and
    <b>secondary headaches</b>. Primary headaches are not associated with
    (caused by) other diseases. Examples of primary headaches are migraine
    headaches, tension headaches, and cluster headaches. Secondary headaches are
    caused by associated disease. The associated disease may be minor or serious
    and life threatening.

    <h4>Treatment:</h4>
    The list of treatments mentioned in various sources for Occipital Neuralgia
    includes the following list. Always seek professional medical advice about
    any treatment or change in treatment plans.
    <ul>
      <li>Symptomatic</li>
      <li>Massage</li>
      <li>Rest</li>
      <li>Antidepressants</li>
      <li>Local nerve blocks</li>
      <li>Steroid injections</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "OccipitalHeadaches"
};
</script>

<style scoped></style>
