<template>
  <div class="sidebar right">
    <b>Address:</b>
    <br />
    Medical City McKinney <br />
    4510 Medical Center Drive, Suite 211 <br />
    McKinney, TX <br />
    75069
    <br />
    <br />
    <b>Phone: </b>
    (469) 541-1613
    <br />
    <br />
    <b>Fax: </b>
    (469) 541-1614
    <br />
    <br />
    <b>Email: </b>
    painmedics@gmail.com
    <br />
    <br />
    <b>Location: </b>
    <router-link to="/directions">Directions/Map</router-link>
    <br />
    <br />
    <b>Office Hours: </b>
    Monday-Friday 8:00AM-5:00PM
    <br />
    <br />
    <b>By appointment only: </b>
    Lunch (12:00PM-1:00PM)
    <br />
    <br />
    <b>After Hours: </b>
    We are available by answering service for emergencies.
  </div>
</template>

<script>
export default {
  name: "RightSidebar"
};
</script>

<style scoped>
.sidebar {
  width: 20%;
  text-align: left;
  font-size: 1vw;
  padding: 2%;
  background-color: white;
  /* box-shadow: 0 0.2em 0.2em 0 rgba(0, 0, 0, 0.103), 0 0.2em 2em 0 rgba(0, 0, 0, 0.19); */
  position: sticky;
  top: 8vw;
}

.right {
  float: right;
}
</style>
