<template>
  <div class="content">
    <h2>Radiofrequency lesioning</h2>
    <hr />
    <h4>What is it?</h4>
    Radiofrequency ablation is a procedure using a specialized machine that
    generates radiofrequency current. A special needle is placed next to the
    nerve which carries pain from spinal facet joints to the spinal cord. The
    radiofrequency current is passed through the needle. The current generates
    heat or other physical forces which interrupts transmission of pain. These
    nerves recover function slowly with time. Radiofrequency Lesioning is a
    procedure using a specialized machine to interrupt nerve conduction on a
    semi-permanent basis. The nerves are usually blocked for 6-9 months (can be
    as short as 3 months or as long as 18 months).

    <h4>Why is it performed?</h4>
    The procedure disrupts nerve conduction (such as conduction of pain
    signals), and it may in turn reduce pain and other related symptoms.
    Approximately 70-80% of patients will get a good block of the intended
    nerve. This should help relieve that part of the pain that the blocked nerve
    controls. Sometimes after a nerve is blocked, it becomes clear that there is
    pain from the other areas as well.

    <h4>How is it done?</h4>
    Since nerves cannot be seen on x-ray, the needles are positioned using bony
    landmarks that indicate where the nerves usually are.
    <b>Fluoroscopy</b> (x-ray) is used to identify those bony landmarks. A local
    anesthetic (like Novocaine) is injected to minimize the discomfort. After
    confirmation of the needle tip position, a special needle tip is inserted.
    When the needle is in good position, as confirmed by x-ray, electrical
    stimulation is done before any lesioning. This stimulation may produce a
    buzzing or tingling or pressure sensation or may be like hitting your "funny
    bone". You may also feel your muscles jump. You need to be awake during this
    part of the procedure so you can report what you're feeling. The tissues
    surrounding the needle tip are then heated (when electronic current is
    passed using the Radiofrequency machine) for a few seconds. This "numbs" the
    nerves semi-permanently.
  </div>
</template>

<script>
export default {
  name: "RadiofrequencyLesioning"
};
</script>

<style scoped></style>
