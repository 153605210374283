<template>
  <div class="content">
    <h2>Complex regional pain syndrome</h2>
    <hr />
    <h4>Introduction:</h4>
    Complex regional pain syndrome is pain that may occur after an injury to an
    arm or a leg. In rare cases, the syndrome develops after surgery, a heart
    attack, a stroke or other medical problem. The pain is often described as a
    burning feeling and is much worse than expected for the injury. Your doctor
    may also call this condition reflex sympathetic dystrophy or causalgia.

    <h4>Causes:</h4>
    Complex regional pain syndrome occurs in two types with similar signs and
    symptoms, but different causes:
    <ul>
      <li>
        <h5>Type I</h5>
        Previously known as <b>reflex sympathetic dystrophy</b>
        syndrome, this type occurs after an illness or injury that didn't
        directly damage the nerves in your affected limb. About 90 percent of
        people with complex regional pain syndrome have Type I.
      </li>
      <li>
        <h5>Type II</h5>
        Once referred to as causalgia, this type follows a distinct nerve
        injury.
      </li>
    </ul>
    Many cases of complex regional pain syndrome occur after a forceful trauma
    to an arm or a leg, such as a gunshot wound or shrapnel blast. Other major
    and minor traumas (surgery, heart attacks, infections, fractures and even
    sprained ankles) also can lead to complex regional pain syndrome. It's not
    well understood why these injuries sometimes trigger complex regional pain
    syndrome.

    <h4>Treatment:</h4>
    Dramatic improvement and even remission of complex regional pain syndrome is
    possible if treatment begins within a few months of your first symptoms.
    Treatment options include:
    <ul>
      <li>
        <h5>Medications</h5>
        Doctors use various medications to treat the symptoms of complex
        regional pain syndrome. Over-the-counter nonsteroidal anti-inflammatory
        drugs (NSAIDs), such as aspirin, ibuprofen (Advil, Motrin, others) and
        naproxen sodium (Aleve), may ease pain and inflammation. In some cases,
        doctors may recommend prescription medications. For example,
        antidepressants such as amitriptyline and anticonvulsants such as
        gabapentin (Neurontin) are used to treat pain that originates from a
        damaged nerve (neuropathic pain). Corticosteroids, such as prednisone,
        may reduce inflammation. <br />Your doctor may suggest bone-loss
        medications, such as alendronate (Fosamax) and calcitonin (Miacalcin).
        Opioid medications may be another option. Taken in appropriate doses,
        they may provide acceptable control of pain. However, they may not be
        appropriate if you have a history of substance abuse or lung disease.
        Some pain medications, such as COX-2 inhibitors (Celebrex), may increase
        your risk of heart attack and stroke. It's wise to discuss your
        individual risk profile with your doctor.
      </li>
      <li>
        <h5>Spinal cord stimulation</h5>
        Your doctor inserts tiny electrodes along your spinal cord. A small
        electrical current delivered to the spinal cord sometimes results in
        pain relief.
      </li>
      <li>
        <h5>Biofeedback</h5>
        In some cases, learning biofeedback techniques may help. In biofeedback,
        you learn tobecome more aware of your body so that you can relax your
        body and relieve pain.
      </li>
      <li>
        <h5>Transcutaneous electrical nerve stimulation (TENS)</h5>
        Chronic pain is sometimes eased by applying electrical impulses to nerve
        endings.
      </li>
      <li>
        <h5>Sympathetic nerve-blocking medication</h5>
        Injection of an anesthetic to block pain fibers in your affected nerves
        may relieve pain in some people.
      </li>
      <li>
        <h5>Physical therapy</h5>
        Gentle, guided exercising of the affected limbs may improve range of
        motion and strength. The earlier the disease is diagnosed, the more
        effective exercises may be.
      </li>
      <li>
        <h5>Capsaicin</h5>
        This cream, made from the seeds of hot chili peppers, may relieve pain
        caused by nerve damage in early-stage complex regional pain syndrome.
        Your doctor may recommend applying the cream to the affected area
        several times daily. Capsaicin cream can be very irritating if rubbed on
        nonaffected parts of your body. Follow the application instructions
        carefully. You should be able to tell within a week whether the
        treatment is effective and tolerable.
      </li>
      <li>
        <h5>Applying heat and cold</h5>
        Applying cold may relieve swelling and sweating. If the affected area is
        cool, applying heat may offer relief.
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "ComplexRegionalPain"
};
</script>

<style scoped></style>
