<template>
  <div class="content">
    <h2>Central pain syndrome</h2>
    <hr />
    <h4>Introduction:</h4>
    Central Pain is the name for a pain syndrome which occurs when injury to the
    Central Nervous System is insufficient to cause numbness but sufficient to
    cause central sensitization of the pain system. <br />Reflecting the neuron
    of choice of the original nerve physiologists, nearly everything taught in
    medical school about basic nerve physiology derived from studies of motor
    nerves. Because sensory nerves behave almost nothing like motor nerves, the
    behavior of Central Pain may seem anti-intuitive to those fed a diet of
    motor nerve physiology for most of their training. <br />The process can
    become so violent that the thalamus, the brain pain center, records "bursts"
    of impulses from these injured nerves. After sufficient bombardment
    threatens neuron death in the thalamus, it "shuts down". Central Pain
    apparently occurs at this point. Radiostimulation of the spinothalamic
    tract, which causes no sensation in people without Central Pain, recreates
    the sensation of Central Pain in those who have the condition. It is as if
    the entire pain system is acting like a nerve ending. Ungated pain signals
    thus reach the cortex, causing unbearable suffering.

    <h4>Causes:</h4>
    The list of possible underlying conditions mentioned in various sources for
    Central Pain Syndrome includes:
    <ul>
      <li>Brain trauma</li>
      <li>CNS trauma</li>
      <li>Strokes</li>
      <li>Multiple sclerosis</li>
      <li>Limb amputations</li>
    </ul>

    <h4>Treatment:</h4>
    Treatment for central pain syndrome (CPS) has varying degrees of success.
    Some are able to manage it with medications, generally anti-convulsants and
    anti-depressants. But finding the right medication regimen takes time.
    <br />Narcotics are the best drugs for pain because they bind to pain
    receptors specifically. Narcotic analgesics like morphine, methadone and
    heroin are very effective. If it gets where you're taking too much, you can
    get an implantable pump. Because you are putting the drug right in the
    spine, you can use tiny amounts that just affect the nervous system, so you
    don't get a lot of the side effects that you get when taking it orally or by
    injection. <br />The absolute last resort is neurosurgery, such as deep
    brain stimulation where an electrode is implanted and sends stimulation to
    the pain receptors. Finding the right doctor is crucial. Some find the right
    one on their first neurologist visit. Others have to go through many before
    finding the one who believes them and begins the treatment process.
  </div>
</template>

<script>
export default {
  name: "CentralPain"
};
</script>

<style scoped></style>
