<template>
  <div class="content">
    <h2>Medial branch blocks</h2>
    <hr />
    <h4>What is it?</h4>
    Medial branch nerves are very small nerve branches that carry the pain
    message from the facet joints and the muscles around the joints. If the
    nerves are blocked or numbed, they will not be able to transfer the pain
    sensation from the joints to the brain.

    <h4>Why is it performed?</h4>
    If the nerves are blocked or numbed, they will not be able to carry pain
    sensation to the spinal cord. It is like temporarily cutting off wires.
    Therefore, if the pain is due to facet joint arthritis, you should have
    relief from pain and stiffness. Once it is determined that the pain is
    indeed due to facet joint disease, we can use a procedure called
    <router-link to="/patientEducation/radioFrequencyLesioning"
      >Radiofrequency Lesioning
    </router-link>
    and prevent the conduction of pain information for several weeks to months.
    So, in a way, medial branch block is a temporary and diagnostic procedure.

    <h4>How is it done?</h4>
    It is done either with the patient lying on the stomach for the upper and
    low back pain, or for the cervical (neck area) injections in sitting
    position or lying on the back, under x-ray control. The patients are
    monitored with EKG, blood pressure cuff and blood oxygen-monitoring device.
    The skin in the back is cleaned with antiseptic solution and then the
    injection is carried out.
  </div>
</template>

<script>
export default {
  name: "MedialBlocks"
};
</script>

<style scoped></style>
