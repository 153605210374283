<template>
  <div class="content">
    <h2>Ganglion of impar block</h2>
    <hr />
    <h4>What is it?</h4>
    The <b>ganglion impar</b> is a solitary retroperitoneal structure located at
    the level of the sacrococcygeal junction. This unpaired ganglion marks the
    end of the two sympathetic chains. Visceral pain in the perineal area
    associated with malignancies may be effectively treated with neurolysis of
    the ganglion impar.

    <h4>Why is it performed?</h4>
    Patients who will benefit from this block frequently present with a vague,
    poorly localized pain that is frequently accompanied by sensations of
    burning and urgency. However, the clinical value of this block is not clear
    as the published experienced is limited.

    <h4>How is it done?</h4>
    This block may be performed with the patient in the left lateral decubitus
    position with the knees flexed, in the lithotomy position, or in the prone
    position. The easiest approach is with the patient in the prone position,
    where a 20-g 1.5-inch needle is inserted through the sacrococcygeal ligament
    in the midline. The needle is then advanced until the tip is placed
    posterior to the rectum.
  </div>
</template>

<script>
export default {
  name: "ImparBlock"
};
</script>

<style scoped></style>
