<template>
  <div>
    <div class="bar" :class="{ 'scroll-shadow': scrolled }">
      <div class="content">
        <h2>NOTICE OF ADDRESS CHANGE:</h2>
        We have moved to a new address on <b>January 28th, 2023</b>. Please see
        this website for updated address information and forms. <br /><br />
        New Address:
        <b>
          Medical City McKinney, 4510 Medical Center Drive Ste. 211, McKinney TX
          75069
        </b>
        <br />
        Phone Number: <b>(469) 541-1613</b> | Fax: <b>(469) 541-1614</b>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AddressChangeBanner",
  data() {
    return {
      scrolled: false
    };
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    onScroll() {
      // Get the current scroll position
      const currentScrollPosition =
        window.pageYOffset || document.documentElement.scrollTop;

      // Because of momentum scrolling on mobiles, we shouldn't continue if it is less than zero
      if (currentScrollPosition < 0) {
        return;
      }

      // Here we determine whether we need to show or hide the navbar
      this.scrolled = currentScrollPosition !== 0;
    }
  }
};
</script>

<style scoped>
.bar {
  width: 80%;
  height: 13vw;
  overflow: hidden;
  display: block;
  left: 0;
  background-color: #1976d2;
  color: white;
  float: right;
  transition: 0.3s;
}

.scroll-shadow {
  box-shadow: 0px 6px 17px 0px rgba(181, 181, 181, 0.25);
}

.content {
  opacity: 1;
  vertical-align: center;
  padding-top: 1.5%;
  font-size: 1vw;
}

.vertical-spacer {
  width: 1%;
  display: block;
  height: 2vw;
  float: left;
}
</style>
