<template>
  <div>
    <h2>Physician</h2>
    <hr />
    <div class="image">
      <img src="../../assets/images/aj1.jpeg" />
      <h4>Ajay Harpavat, MD</h4>
      <small>Pain Management Specialist</small>
    </div>
    <div class="vertical-spacer"></div>
    <div id="content-1" class="content">
      Dr. Harpavat is proficient in medically managing complex chronic pain
      patients as well as performing diagnostic and therapeutic interventional
      modalities. Dr. Harpavat completed fellowship training in Pain Management
      from the Baystate Medical Center of Tufts-New England University in
      Massachusetts after finishing his Anesthesiology residency from the
      University of Cincinnati. His training included all aspects of chronic and
      acute pain management, including diagnosis and management of back pain,
      neck pain, occipital and cervicogenic headaches, complex regional pain
      syndrome, neuropathic pain, pelvic pain, as well as cancer pain, employing
      various modalities for management. He is board-certified in Anesthesiology
      as well as specialty boarded in Pain Management.
    </div>
    <div id="content-2" class="content">
      Areas of special interest to Dr. Harpavat include complex regional pain
      syndrome and cancer pain. He has received numerous awards during his
      fellowship training, including the National Pfizer Scholars in Pain
      Management Award as well as the RSD Hope Hero Award. <br />Dr. Harpavat
      offers his services for all in need of pain management with prompt and
      timely evaluation and treatment as needed. <br />Dr. Harpavat moved to the
      area from a group practice in California to be closer to family. He enjoys
      hiking, backpacking, mountaineering and travelling during his free time.
    </div>
  </div>
</template>

<script>
export default {
  name: "Team"
};
</script>

<style scoped>
.content {
  font-size: 1.2vw;
}

h4 {
  font-size: 1.5vw;
  padding-bottom: 0;
  margin-bottom: 0;
}

small {
  padding-top: 0;
  margin-top: 0;
  font-size: 1vw;
}

.image {
  height: 35%;
  width: 35%;
  float: left;
  text-align: center;
}

img {
  max-width: 100%;
}

.vertical-spacer {
  width: 3.5%;
  display: block;
  height: auto;
  float: left;
}

#content-1 {
  width: 60%;
  float: right;
}

#content-2 {
  width: 100%;
  float: left;
  margin-top: 2vw;
}

.horizontal-spacer {
  height: 1.5em;
  width: 100%;
  float: left;
}
</style>
