// import Vue from "vue";
// import Router from "vue-router";
// import Home from "./views/Home.vue";

// Vue.use(Router);

// export default new Router({
//   mode: "history",
//   base: process.env.BASE_URL,
//   routes: [
//     {
//       path: "/",
//       name: "home",
//       component: Home
//     },
//     {
//       path: "/about",
//       name: "about",
//       // route level code-splitting
//       // this generates a separate chunk (about.[hash].js) for this route
//       // which is lazy-loaded when the route is visited.
//       component: () =>
//         import(/* webpackChunkName: "about" */ "./views/About.vue")
//     }
//   ]
// });

import Vue from "vue";
import Router from "vue-router";
import ContentWrapper from "@/components/ContentWrapper";
import Home from "@/components/content/Home";
import FAQs from "@/components/content/FAQs";
import Map from "@/components/content/Map";
import Forms from "@/components/content/Forms";
import Team from "@/components/content/Team";
import PatientEducation from "@/components/content/PatientEducation";
import Error404 from "@/components/404";

import pted from "@/components/content/PatientEd";

Vue.use(Router);

export default new Router({
  mode: "history",
  linkExactActiveClass: "is-active",
  routes: [
    {
      path: "/",
      name: "HelloWorld",
      component: ContentWrapper,
      redirect: "/home",
      children: [
        {
          path: "/robotst",
          name: "Home",
          component: Home
        },
        {
          path: "/home",
          name: "Home",
          component: Home
        },
        {
          path: "/faqs",
          name: "FAQs",
          component: FAQs
        },
        {
          path: "/directions",
          name: "Directions/Map",
          component: Map
        },
        {
          path: "/forms",
          name: "Forms",
          component: Forms
        },
        {
          path: "/aboutUs",
          name: "AboutUs",
          component: Team
        },
        {
          path: "/patientEducation",
          name: "PatientEducation",
          component: PatientEducation,
          redirect: "/patientEducation/resources",
          children: [
            {
              path: "resources",
              name: "EducationList",
              component: pted.EducationList
            },
            {
              path: "backPain",
              name: "BackPain",
              component: pted.BackPain
            },
            {
              path: "neckShoulderPain",
              name: "NeckShoulderPain",
              component: pted.NeckShoulderPain
            },
            {
              path: "failedBackSurgery",
              name: "FailedBackSurgery",
              component: pted.BackSurgery
            },
            {
              path: "costochondralJunctionPain",
              name: "CostochondralPain",
              component: pted.CostochondralPain
            },
            {
              path: "coccydynia",
              name: "Coccydynia",
              component: pted.Coccydynia
            },
            {
              path: "centralPainSyndrome",
              name: "CentralPain",
              component: pted.CentralPain
            },
            {
              path: "complexRegionalPainSyndrome",
              name: "ComplexRegionalPain",
              component: pted.ComplexRegionalPain
            },
            {
              path: "herpeticNeuralgia",
              name: "HerpeticNeuralgia",
              component: pted.HerpeticNeuralgia
            },
            {
              path: "postDuralPunctureHeadache",
              name: "PunctureHeadache",
              component: pted.PunctureHeadache
            },
            {
              path: "spasticity",
              name: "Spasticity",
              component: pted.Spasticity
            },
            {
              path: "refractoryPelvicPain",
              name: "PelvicPain",
              component: pted.PelvicPain
            },
            {
              path: "amputationPain",
              name: "AmputationPain",
              component: pted.AmputationPain
            },
            {
              path: "trigeminalNeuralgia",
              name: "TrigeminalNeuralgia",
              component: pted.TrigeminalNeuralgia
            },
            {
              path: "arthriticPain",
              name: "ArthriticPain",
              component: pted.ArthriticPain
            },
            {
              path: "occipitalHeadaches",
              name: "OccipitalHeadaches",
              component: pted.OccipitalHeadaches
            },
            {
              path: "cervicogenicHeadaches",
              name: "CervicogenicHeadaches",
              component: pted.CervicogenicHeadaches
            },
            {
              path: "selectiveNervesRootBlocks",
              name: "RootBlocks",
              component: pted.RootBlocks
            },
            {
              path: "sacroiliacJointInjections",
              name: "SacroiliacInjections",
              component: pted.SacroiliacInjections
            },
            {
              path: "facetJointInjections",
              name: "FacetInjections",
              component: pted.FacetInjections
            },
            {
              path: "medialBranchBlocks",
              name: "MedialBlocks",
              component: pted.MedialBlocks
            },
            {
              path: "peripheralNerveBlocks",
              name: "PeripheralBlocks",
              component: pted.PeripheralBlocks
            },
            {
              path: "triggerPointInjections",
              name: "TriggerInjections",
              component: pted.TriggerInjections
            },
            {
              path: "radiofrequencyLesioning",
              name: "RadiofrequencyLesioning",
              component: pted.RadiofrequencyLesioning
            },
            {
              path: "discography",
              name: "Discography",
              component: pted.Discography
            },
            {
              path: "spinalCordStimulation",
              name: "SpinalStimulation",
              component: pted.SpinalStimulation
            },
            {
              path: "intrathecalPumpImplantation",
              name: "IntrathecalPump",
              component: pted.IntrathecalPump
            },
            {
              path: "celiacPlexusBlock",
              name: "CeliacBlock",
              component: pted.CeliacBlock
            },
            {
              path: "ganglionOfImparBlock",
              name: "ImparBlock",
              component: pted.ImparBlock
            },
            {
              path: "trigeminalGanglionBlock",
              name: "TrigeminalBlock",
              component: pted.TrigeminalBlock
            },
            {
              path: "lumbarSympatheticBlock",
              name: "LumbarBlock",
              component: pted.LumbarBlock
            },
            {
              path: "stellateGanglionBlock",
              name: "StellateBlock",
              component: pted.StellateBlock
            },
            {
              path: "epiduralSteroidInjections",
              name: "EpiduralInjections",
              component: pted.EpiduralInjections
            }
          ]
        },
        {
          path: "404",
          name: "Error404",
          component: Error404
        }
      ]
    },
    {
      path: "*",
      redirect: "/home"
    }
  ]
});
