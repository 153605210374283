import { render, staticRenderFns } from "./PunctureHeadache.vue?vue&type=template&id=62bc72ba&scoped=true"
import script from "./PunctureHeadache.vue?vue&type=script&lang=js"
export * from "./PunctureHeadache.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62bc72ba",
  null
  
)

export default component.exports