import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "./registerServiceWorker";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faPhoneAlt,
  faEnvelope,
  faCopyright,
  faMapMarkerAlt
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faPhoneAlt, faEnvelope, faCopyright, faMapMarkerAlt);

Vue.component("fa-icon", FontAwesomeIcon);

// Vue.config.productionTip = false;

/* eslint-disable no-new */
new Vue({
  el: "#app",
  router,
  render: h => h(App),
  components: { App },
  template: "<App/>"
}).$mount("#app");

Vue.config.productionTip = false;

// new Vue({
//   router,
//   render: h => h(App)
// }).$mount("#app");
