<template>
  <div class="content">
    <h2>Celiac plexus block</h2>
    <hr />
    <h4>What is it?</h4>
    The <b>celiac plexus</b> is situated retroperitoneally in the upper abdomen.
    It is at the level of the T12 and L1vertebrae, anterior to the crura of the
    diaphragm. The celiac plexus surrounds the abdominal aorta and the celiac
    and superior mesenteric arteries. The plexus is composed of a network of
    nerve fibers from both the sympathetic and the parasympathetic systems. It
    contains two large ganglia that receive sympathetic fibers from the three
    splanchnic nerves (greater, lesser, and least). The plexus also receives
    parasympathetic fibers from the vagus nerve. Autonomic nerves supplying to
    the liver, pancreas, gallbladder, stomach, spleen, kidneys, intestines, and
    adrenal glands, as well as blood vessels, arise in the celiac plexus.

    <h4>Why is it performed?</h4>
    A celiac plexus block is used for pain control. This procedure blocks the
    nerves which come from the pancreas, liver, gall bladder, stomach and
    intestine. A celiac plexus block consists of injections of a local
    anesthetic, steroid or ethy-alcohol (phenol). The use of alcohol, called a
    <b>neurolytic block</b>, destroys the nerves. A trial block is done using a
    local anesthetic before a neurolytic block is performed. If you get short
    term pain relief from the local anesthetic, then a neurolytic block is done.

    <h4>How is it done?</h4>
    A celiac plexus block is done in the CT scan room. You will be asked to lie
    facedown on the CT scan table. An IV will be started. Using x-ray guidance,
    a needle is advanced to the correct location. The local anesthetic, with or
    without the steroid, is injected and the needle is removed. The procedure
    takes about 30 minutes. <br />Three approaches to block nociceptive impulses
    from the viscera of the upper abdomen include the <b>theretrocrural</b> (or
    classic) approach, the <b>anterocrural</b> approach, and
    <b>neurolysis</b> of the splanchnic nerves. With all of these approaches,
    the needles are inserted at the level of the first lumbar vertebra, 5 to 7
    cm from the midline. Then, the tip of the needle is directed towards the
    body of L-1 for the retrocrural and anterocrural approaches and to the body
    of T-12 for neurolysis of the splanchnic nerves. More recently, computed
    tomography and ultrasound techniques have allowed pain specialists to
    perform neurolysis of the celiac plexus via a trans-abdominal approach. This
    approach is frequently used when patients are unable to tolerate either the
    prone or lateral decubitus positioner when their liver is so enlarged that a
    posterior approach is not feasible.
  </div>
</template>

<script>
export default {
  name: "CeliacBlock"
};
</script>

<style scoped></style>
