import { render, staticRenderFns } from "./PeripheralBlocks.vue?vue&type=template&id=ec432860&scoped=true"
import script from "./PeripheralBlocks.vue?vue&type=script&lang=js"
export * from "./PeripheralBlocks.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ec432860",
  null
  
)

export default component.exports