<template>
  <div class="content">
    <h2>Back pain of various etiologies</h2>
    <hr />
    <h4>Introduction:</h4>
    Low back and neck pain is a common problem and one of enormous social,
    psychologic, and economic burden. It is estimated that 15% to 20% of adults
    have back pain during a single year and 50% to 80% experience at least one
    episode of back pain during a lifetime. Low back pain afflicts all ages,
    from adolescents to the elderly, and is a major cause of disability in the
    adult working population. Risk factors for developing spine pain are
    multidimensional; physical attributes, socioeconomic status, general medical
    health and psychologic state, and occupational environmental factors all
    contribute to the risk for experiencing pain. <br />
    While rare, certain back pain symptoms are a sign of a medical emergency
    that may become life threatening or result in disability without prompt
    attention from an appropriately skilled physician.

    <h4>Causes:</h4>
    <ul>
      <li>
        <h5>Lumbar Muscle Strain</h5>
        Muscle strains are the most common cause of low back pain. Patients may
        or may not remember the initial event that triggered their muscle spasm,
        but the good news is that most episodes of back pain from muscle strains
        resolve completely within a few weeks.
      </li>
      <li>
        <h5>Ruptured Disc</h5>
        A ruptured intervertebral disc, also called a herniated disc, is another
        common cause of back pain. How to treat the back pain from a herniated
        disc depends on the particular individual and situation.
      </li>
      <li>
        <h5>Discogenic Back Pain</h5>
        Discogenic back pain is thought to be a common cause of low back pain.
        Discogenic back pain cis the result of damage to the intervertabral
        disc, but without disc herniation. Diagnosis of discogenic back pain may
        require the use of a discogram.
      </li>
      <li>
        <h5>Spinal Stenosis</h5>
        Spinal stenosis causes back pain in the aging population. As we age, the
        spinal canal can become constricted, due in part to arthritis and other
        conditions. If the spinal canal becomes too tight, back pain can be the
        result.
      </li>
      <li>
        <h5>Arthritis</h5>
        Arthritis most commonly affects joints such as the knees and fingers.
        However, arthritis can affect any joint in the body, including the small
        joints of the spine. Arthritis of the spine can cause back pain with
        movement.
      </li>
    </ul>

    <h4>Treatment:</h4>
    The most frustrating aspect in treatment of back pain is that there is no
    "magic bullet." Most individuals recover completely by simply avoiding
    strain to their spine. Patients often find help from ice, heat, and
    medications. If the basic steps do not alleviate back pain, the next step is
    to seek medical evaluation. Depending on the symptoms and the length of the
    problem, your physician can properly organize a treatment schedule.
  </div>
</template>

<script>
export default {
  name: "BackPain"
};
</script>

<style scoped>
h5 {
  font-size: 1.2vw;
  margin: 0.5em;
  margin-left: 0;
}
</style>
