<template>
  <div class="content">
    <h2>Peripheral nerve blocks</h2>
    <hr />
    <h4>What is it?</h4>
    Peripheral nerve blocks are used in selected procedures as part of a
    preemptive and multimodal or balanced analgesia technique to provide safe
    intraoperative and postoperative analgesia with minimal side effects. The
    goal of preemptive analgesia is to minimize pain from surgically injured
    tissue by providing medications (eg, local anesthetics, nonsteroidal
    anti-inflammatory drugs [NSAIDs], opioids) before the surgical injury occurs
    and before pain is perceived.

    <h4>Why is it performed?</h4>
    Peripheral nerve blocks target the nociceptive impulses transmitted along
    peripheral nerves. Blocks also can be combined with additional medications
    to target pain at other levels. For example, NSAIDs reduce the activation of
    nociceptors at the site of injury and modify the cortical response, whereas,
    opioids relieve pain by binding to opioid receptors in the brain and spinal
    cord to modify the perception of pain.

    <h4>How is it done?</h4>
    A nerve block needle, or a peripheral nerve catheter, is inserted in the
    vicinity of the relevant peripheral nerve. A continuous infusion of local
    anesthetic via a pump device ensures long-lasting pain relief. Clinicians
    with specific technical expertise are required for the administration of the
    nerve block, and other, equally skilled, clinicians are required to monitor
    patients intraoperatively and postoperatively. Nurses must be experienced in
    the preoperative screening and assessment of this patient population and
    must participate in educating patients and their family members. During the
    intraoperative and postoperative phases, nurses must observe patients for
    potential side effects and complications and be prepared to provide
    preventive measures and assistance with any treatment interventions.
  </div>
</template>

<script>
export default {
  name: "PeripheralBlocks"
};
</script>

<style scoped></style>
