<template>
  <div id="app">
    <app-content />
  </div>
</template>

<script>
import AppContent from "@/components/AppContent";

export default {
  name: "App",
  components: {
    AppContent
  }
};
</script>

<style>
@import url("https://fonts.googleapis.com/css?family=Noto+Sans+HK&display=swap");

#app {
  font-family: "Noto Sans HK", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 3%; */
}

body {
  background-color: white;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.9)
    ),
    url("./assets/images/background.png");
  background-size: 35%;
  background-repeat: no-repeat;
  background-position: 50% 7vw;
  background-attachment: fixed;
}
</style>
