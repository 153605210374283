<template>
  <div class="content">
    <h2>Trigger point injections</h2>
    <hr />
    <h4>What is it?</h4>
    Trigger point injection (TPI) is used to treat extremely painful areas of
    muscle. Normal muscle contracts and relaxes when it is active. A
    <b>trigger point</b> is a knot or tight, ropy band of muscle that forms when
    muscle fails to relax. The knot often can be felt under the skin and may
    twitch involuntarily when touched (called a jump sign). <br />The trigger
    point can trap or irritate surrounding nerves and cause
    <b>referred pain</b> (pain felt in another part of the body). Scar tissue,
    loss of range of motion, and weakness may develop over time.

    <h4>Why is it performed?</h4>
    TPI is used to alleviate <b>myofascial pain syndrome</b> (chronic pain
    involving tissue that surrounds muscle) that does not respond to other
    treatment, although there is some debate over its effectiveness. Many muscle
    groups, especially those in the arms, legs, lower back, and neck, are
    treated by this method. TPI also can be used to treat fibromyalgia and
    tension headaches.

    <h4>How is it done?</h4>
    Medical specialists (such as an orthopedist, physiatrist, pain specialist,
    or neurologist) can administer TPI. Injections are given in the physician's
    office and take approximately 30 minutes. Before performing TPI, the
    physician may give the patient a nerve block to prevent pain from needle
    penetration. A small needle is inserted into the trigger point and a local
    anesthetic (e.g., lidocaine, procaine) with or without a corticosteroid is
    injected. Injection of medication inactivates the trigger point and thus
    alleviates pain. Sustained relief usually is achieved with a brief course of
    treatment. The injection may cause a twitch or pain that lasts a few seconds
    to a few minutes.
  </div>
</template>

<script>
export default {
  name: "TriggerInjections"
};
</script>

<style scoped></style>
