<template>
  <div class="content">
    <h2>You may want to know more about</h2>
    <hr />
    <h4>Clinical Conditions</h4>
    <ul>
      <li>
        <router-link to="/patientEducation/backPain"
          >Back pain of various etiologies
        </router-link>
      </li>
      <li>
        <router-link to="/patientEducation/neckShoulderPain"
          >Neck/shoulder pain of various etiologies
        </router-link>
      </li>
      <li>
        <router-link to="/patientEducation/failedBackSurgery"
          >Failed back surgery syndrome
        </router-link>
      </li>
      <li>
        <router-link to="/patientEducation/costochondralJunctionPain"
          >Costochondral junction pain
        </router-link>
      </li>
      <li>
        <router-link to="/patientEducation/coccydynia">Coccydynia </router-link>
      </li>
      <!-- <li>
            <router-link to="/patientEducation">Vascular ischemic pain
            </router-link>
        </li> -->
      <li>
        <router-link to="/patientEducation/centralPainSyndrome"
          >Central pain syndrome
        </router-link>
      </li>
      <li>
        <router-link to="/patientEducation/complexRegionalPainSyndrome"
          >Complex regional pain syndrome
        </router-link>
      </li>
      <li>
        <router-link to="/patientEducation/herpeticNeuralgia"
          >Acute herpetic and post-herpetic neuralgia
        </router-link>
      </li>
      <li>
        <router-link to="/patientEducation/postDuralPunctureHeadache"
          >Post-dural puncture headache
        </router-link>
      </li>
      <li>
        <router-link to="/patientEducation/spasticity">Spasticity </router-link>
      </li>
      <li>
        <router-link to="/patientEducation/refractoryPelvicPain"
          >Refractory pelvic pain
        </router-link>
      </li>
      <!-- <li>
            <router-link to="/patientEducation">Primary and secondary cancer pain of various regions
            </router-link>
        </li> -->
      <!-- <li>
            <router-link to="/patientEducation">Facial pain
            </router-link>
        </li> -->
      <li>
        <router-link to="/patientEducation/amputationPain"
          >Phantom limb pain/post-amputation pain/stump pain
        </router-link>
      </li>
      <li>
        <router-link to="/patientEducation/trigeminalNeuralgia"
          >Trigeminal neuralgia
        </router-link>
      </li>
      <li>
        <router-link to="/patientEducation/arthriticPain"
          >Arthritic pain
        </router-link>
      </li>
      <li>
        <router-link to="/patientEducation/occipitalHeadaches"
          >Occipital headaches
        </router-link>
      </li>
      <li>
        <router-link to="/patientEducation/cervicogenicHeadaches"
          >Cervicogenic headaches
        </router-link>
      </li>
    </ul>
    <h4>Interventional Modalities</h4>
    <ul>
      <li>
        <router-link to="/patientEducation/epiduralSteroidInjections"
          >Cervical, lumbar, &#38; thoracic epidural steroid injections
        </router-link>
      </li>
      <li>
        <router-link to="/patientEducation/selectiveNervesRootBlocks"
          >Selective nerves root blocks
        </router-link>
      </li>
      <!-- <li>
            <router-link to="/patientEducation">Transforaminal epidural steroid injections
            </router-link>
        </li> -->
      <li>
        <router-link to="/patientEducation/sacroiliacJointInjections"
          >Sacroiliac joint injections
        </router-link>
      </li>
      <li>
        <router-link to="/patientEducation/facetJointInjections"
          >Facet joint injections
        </router-link>
      </li>
      <li>
        <router-link to="/patientEducation/medialBranchBlocks"
          >Medial branch blocks
        </router-link>
      </li>
      <li>
        <router-link to="/patientEducation/peripheralNerveBlocks"
          >Peripheral nerve blocks
        </router-link>
      </li>
      <!-- <li>
            <router-link to="/patientEducation">Intra-articular injections
            </router-link>
        </li> -->
      <li>
        <router-link to="/patientEducation/triggerPointInjections"
          >Trigger point injections
        </router-link>
      </li>
      <li>
        <router-link to="/patientEducation/radiofrequencyLesioning"
          >Radiofrequency lesioning
        </router-link>
      </li>
      <!-- <li>
            <router-link to="/patientEducation">Dupens catheter insertion
            </router-link>
        </li> -->
      <!-- <li>
            <router-link to="/patientEducation">Occipital nerve stimulation
            </router-link>
        </li> -->
      <li>
        <router-link to="/patientEducation/discography"
          >Discography
        </router-link>
      </li>
      <li>
        <router-link to="/patientEducation/spinalCordStimulation"
          >Spinal cord stimulation
        </router-link>
      </li>
      <li>
        <router-link to="/patientEducation/intrathecalPumpImplantation"
          >Intrathecal pump implantation
        </router-link>
      </li>
      <li>
        <router-link to="/patientEducation/celiacPlexusBlock"
          >Celiac plexus block
        </router-link>
      </li>
      <li>
        <router-link to="/patientEducation/ganglionOfImparBlock"
          >Ganglion of impar block
        </router-link>
      </li>
      <li>
        <router-link to="/patientEducation/trigeminalGanglionBlock"
          >Trigeminal ganglion block
        </router-link>
      </li>
      <li>
        <router-link to="/patientEducation/lumbarSympatheticBlock"
          >Lumbar sympathetic block
        </router-link>
      </li>
      <li>
        <router-link to="/patientEducation/stellateGanglionBlock"
          >Stellate ganglion block
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "EducationList"
};
</script>

<style scoped>
ul {
  margin-left: 1vw;
}

ul li {
  margin: 0.5vw;
}
</style>
