<template>
  <nav>
    <ul>
      <li id="home">
        <router-link to="/home">
          Home
        </router-link>
      </li>
      <li id="team">
        <router-link to="/aboutUs">
          The Team
        </router-link>
      </li>
      <li id="pted">
        <router-link to="/patientEducation">
          Patient Education
        </router-link>
      </li>
      <li id="form">
        <router-link to="/forms">
          Forms
        </router-link>
      </li>
      <li id="faqs">
        <router-link to="/faqs">
          FAQs
        </router-link>
      </li>
      <li id="dirs">
        <router-link to="/directions">
          Directions/Map
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "NavBar"
};
</script>

<style scoped>
nav li .router-link-active {
  background-color: white;
  color: #1976d2;
  border-right: 3px solid #039be5;
}

nav {
  background-color: #e9e9e9;
  height: 90vh; /* Full-height: remove this if you want "auto" height */
  width: 20%; /* Set the width of the sidebar */
  position: fixed; /* Fixed Sidebar (stay in place on scroll) */
  z-index: 0; /* Stay on top */
  top: 7vw; /* Stay at the top */
  left: 0;
  overflow-x: hidden; /* Disable horizontal scroll */
  padding: 0;
}

nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  overflow: hidden;
}

nav ul li {
  box-sizing: border-box;
  transition: 0.3s;
}

nav ul li:hover {
  border-right: 3px solid #039be5;
  background: white;
  transition: 0.3s;
}

nav ul li a {
  display: block;
  text-decoration: none;
  transition: 0.3s;
  width: 100%;
  padding: 7.5%;
  text-align: left;
  word-wrap: break-word;
  color: #696969;
  font-size: 1.5vw;
}
</style>
