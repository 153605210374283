<template>
  <div class="hello">
    <title-bar />
    <div class="horizontal-spacer"></div>
    <address-change-banner />
    <nav-bar />
    <div class="horizontal-spacer"></div>
    <page-body />
    <div class="horizontal-spacer"></div>
    <footer-bar />
    <div class="horizontal-spacer"></div>
  </div>
</template>

<script>
import TitleBar from "@/components/TitleBar";
import AddressChangeBanner from "@/components/AddressChangeBanner";
import NavBar from "@/components/NavBar";
import PageBody from "@/components/PageBody";
import FooterBar from "@/components/FooterBar";

export default {
  name: "AppContent",
  components: {
    TitleBar,
    AddressChangeBanner,
    NavBar,
    PageBody,
    FooterBar
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1,
h2 {
  font-weight: normal;
}

.horizontal-spacer {
  height: 5vw;
  width: 80%;
  float: right;
  opacity: 0;
}
</style>
