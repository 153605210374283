<template>
  <div class="content">
    <h2>Selective nerves root blocks</h2>
    <hr />
    <h4>What is it?</h4>
    Selective nerves root blocks is a procedure that targets specific nerves and
    relieves the pain caused by inflammation. To ensure proper placement of the
    medication the procedure is done under fluoroscopy or x-ray. <br />There are
    two types of selective nerve root blocks: <b>diagnostic</b> and
    <b>therapeutic</b>. When one does a diagnostic block, just enough medication
    is placed to numb exactly one root. By doing this, the doctor can determine
    if the nerve root is causing the pain. Therapeutic blocks involve more
    medication and different medication. <br />Nerve roots are attached to the
    spinal cord. One exits each side of the spine at every vertebral level.
    These nerves carry signals throughout the body from the skin to the muscles.
    When one of the nerve roots is irritated, patients may experience pain,
    numbness, tingling, and sometimes weakness down an arm or a leg. A
    diagnostic selective block can be used to prove that a pinched nerve is the
    problem.

    <h4>Why is it performed?</h4>
    SNRB (Selective nerves root blocks) is a procedure that is performed to
    determine if a specific spinal nerve is the source of pain. By injecting
    steroid medication the inflammation is reduced thus alleviating the pain.

    <h4>How is it done?</h4>
    First an IV is started and you may be given medicine for relaxation if you
    so desire. Next, while lying on an x-ray table your skin will be cleansed
    and prepared. A local anesthetic is given to numb the skin surface. A
    physician locates under fluoroscopy a specific nerve root. A needle is then
    inserted and an anesthetic and steroid medication is injected into the area
    bathing the nerve root.
  </div>
</template>

<script>
export default {
  name: "RootBlocks"
};
</script>

<style scoped></style>
