import { render, staticRenderFns } from "./TriggerInjections.vue?vue&type=template&id=78b84dc0&scoped=true"
import script from "./TriggerInjections.vue?vue&type=script&lang=js"
export * from "./TriggerInjections.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78b84dc0",
  null
  
)

export default component.exports