<template>
  <div class="content">
    <h2>Post-dural puncture headache</h2>
    <hr />
    <h4>Introduction:</h4>
    Headache is a common finding in parturients following labour and delivery
    and may be related to multiple causes including dural puncture. Postdural
    puncture headache is classically defined by the presence of headache
    worsened by sitting or standing and relieved in the supine position. It may
    be associated with neckache and less commonly auditory or ocular symptoms.
    Variations in presentation may occur. Less common but serious conditions may
    also mimic postdural puncture headache. Any patient with a moderate to
    severe headache, tinnitus, hearing loss, blurred vision/photophobia or
    postural headache following spinal or epidural anesthesia should be
    evaluated by an anesthesiologist. Postdural puncture headache symptoms may
    require institution of therapy prior to the availability of anesthetic
    consultation. <br />Post-dural puncture headache must be distinguished from
    tension headache, migraine, pre-eclampsia, meningitis, cortical vein
    thrombosis, intracerebral haemorrhage, subdural haematoma and intracranial
    tumour. A history of dural puncture may be absent. The headache varies in
    character but is relieved by lying down and by abdominal compression.
    Associated symptoms include neckache, nausea, vomiting, photophobia and
    diplopia. A neglected dural leak may result in convulsions or cranial
    subdural haematoma, coning and death.

    <h4>Causes:</h4>
    <ul>
      <li>
        <h5>CSF Leakage</h5>
        PDPH occurs in patients receiving either an epidural or spinal
        placement, or a therapeutic or diagnostic lumbar puncture involving
        penetration of the dura mater. Both continuous CSF leakage and pressure
        on intra-cranial vessels are thought to be involved in the development
        of PDHD. Removal of CSF from the subarachnoid space results in postural
        headache which is relieved when saline is administered back into the
        same space. In addition, patients with PDHD have a lower CSF pressure
        and volume than controls.
      </li>
      <li>
        <h5>Pressure</h5>
        A second possible cause of PDPH is pressure on the intracranial vessels
        relaying pain along the trigeminal, ninth and tenth cranial nerves and
        sensory nerves to the neck and occiput. Reflex vasodilation caused by
        pressure on these nerves produces a throbbing pain.
      </li>
    </ul>

    <h4>Treatment:</h4>
    The cause (CSF leakage through a dural hole producing a low CSF pressure),
    mechanism (traction on the pain sensitive structures in the brain and
    vasodilation of the cerebral vessels when the patient is upright), and
    duration (until the hole spontaneously heals and CSF pressure is restored;
    typically lasts a few days and rarely more than one week) helps to define
    the condition known as PDPH and to suggest an approach to treatment.
    <ul>
      <li>
        <h5>Epidural saline injection</h5>
        Boluses or infusion of epidural normal saline can help to transiently
        increase the epidural pressure, slowing the speed at which CSF leaks
        through the dural hole. This may speed the natural healing process. The
        bolus dose is 30-60 mls given 6 hourly for 4 doses. The rate of infusion
        is 1000 mls administered over a 24-hour period. Although epidural saline
        can be a useful technique, higher success rates are often achieved with
        epidural blood patches.
      </li>
      <li>
        <h5>Caffeine</h5>
        Caffeine has been suggested as a mode of therapy to help constrict the
        vasodilated cerebral vessels. It is best administered early in the day
        so that patients can sleep at night. The dose of caffeine sodium
        benzoate is 500 mg intravenously which can be repeated once two hours
        later if the first dose does not have the desired effect.
      </li>
      <li>
        <h5>Analgesics</h5>
        Narcotic analgesics and, in some instances, non-steroidal
        anti-inflammatory agents are often administered for symptomatic
        treatment of the headache.
      </li>
      <li>
        <h5>Bedrest</h5>
        The symptoms of PDPH are alleviated by assuming the horizontal position.
        Attempts at prophylactic treatment by placing the patient horizontal for
        a period of time (eg. 24 hours) after a dural puncture have no effect on
        the incidence or duration of a PDPH; it only delays the onset of the
        PDPH until the patient ambulates.
      </li>
      <li>
        <h5>Hydration</h5>
        Normal hydration of the patient should be maintained. Extra hydration to
        help the body make more CSF does not alleviate the headache. Dehydration
        may make symptoms worse.
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "PunctureHeadache"
};
</script>

<style scoped></style>
