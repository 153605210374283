<template>
  <div class="content">
    <h2>Sacroiliac joint injections</h2>
    <hr />
    <h4>What is it?</h4>
    Sacroiliac joint injection is a procedure that targets lower back pain and
    buttock pain. A steroid medication is injected into the joint. To ensure
    proper placement of the medication, the procedure is done under fluoroscopy
    or x-ray.

    <h4>Why is it performed?</h4>
    The sacroiliac joint is the largest joint of your lower spine in your
    buttock region. This becomes painful and inflamed. The steroid medication
    can reduce the inflammation and thus help alleviate your pain.

    <h4>How is it done?</h4>
    First an IV is started and you may be given medicine for relaxation if you
    so desire. Next, while lying on an x-ray table your skin will be cleansed
    and prepared. Then a needle will be inserted into the joint. An anesthetic
    and steroid medication is slowly injected into the area.
  </div>
</template>

<script>
export default {
  name: "SacroiliacInjections"
};
</script>

<style scoped></style>
