<template>
  <div>
    <div class="bar" :class="{ 'scroll-shadow': scrolled }">
      <div id="logo">
        <img
          src="../assets/images/painmedicslogo-old.png"
          alt="Pain Medics Logo - Comprehensive Pain Management"
        />
      </div>
      <div class="content">
        <ul>
          <li>
            <a href="#">
              <div class="social-icon">
                <fa-icon icon="phone-alt" />
              </div>
              (469) 541-1613
            </a>
          </li>
          <li>
            <a href="mailto:painmedics@gmail.com" target="_blank">
              <div class="social-icon">
                <fa-icon icon="envelope" />
              </div>
              painmedics@gmail.com
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TitleBar",
  data() {
    return {
      scrolled: false
    };
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    onScroll() {
      // Get the current scroll position
      const currentScrollPosition =
        window.pageYOffset || document.documentElement.scrollTop;

      // Because of momentum scrolling on mobiles, we shouldn't continue if it is less than zero
      if (currentScrollPosition < 0) {
        return;
      }

      // Here we determine whether we need to show or hide the navbar
      this.scrolled = currentScrollPosition !== 0;
    }
  }
};
</script>

<style scoped>
.bar {
  width: 100%;
  height: 7vw;
  overflow: hidden;
  display: block;
  position: fixed; /* Fixed Sidebar (stay in place on scroll) */
  z-index: 1; /* Stay on top */
  top: 0; /* Stay at the t5op */
  left: 0;
  background-color: white;
  float: left;
  transition: 0.3s;
}

.scroll-shadow {
  box-shadow: 0px 6px 17px 0px rgba(181, 181, 181, 0.25);
}

#logo {
  padding-top: 1vw;
  float: left;
  height: 5.5vw;
}

img {
  max-height: 100%;
  width: auto;
}

ul {
  width: 80%;
  overflow: hidden;
  margin: 0;
  padding: 0;
  padding-bottom: 2%;
  /* padding-top: 1.5%; */
  font-size: 1.5vw;
}

ul li {
  list-style: none;
  float: right;
  text-align: right;
  width: 50%; /* fallback for non-calc() browsers */
  width: calc(100% / 2);
  vertical-align: center;
}

ul li a {
  display: block;
  text-decoration: none;
  color: #616161;
  padding: 10px 0;
}

.social-icon {
  display: inline;
  vertical-align: center;
  padding-right: 2%;
}

.content {
  opacity: 0.6;
  vertical-align: center;
  padding-top: 1.5%;
}

.vertical-spacer {
  width: 1%;
  display: block;
  height: 2vw;
  float: left;
}
</style>
