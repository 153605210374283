<template>
  <div class="content">
    <h2>Spasticity</h2>
    <hr />
    <h4>Introduction:</h4>
    Spasticity is a disorder of the body's motor system in which certain muscles
    are continuously contracted. This contraction causes stiffness or tightness
    of the muscles and may interfere with gait, movement, and speech. The person
    with the spastic muscles may or may not feel it, know about it or want to do
    something about it. The human motor system is not always linked with the
    sensory systems, nor the voluntary-muscle systems.

    <h4>Causes:</h4>
    Voluntary movement is controlled by several interacting systems within the
    nervous system. Primary control is through the upper motor neurons in the
    motor cortex of the brain, which send their axons via the cortico-spinal
    tract to connect to lower motor neurons in the spinal cord. Spasticity is
    caused by damage to upper motor neurons or to the cortico-spinal tract. It
    may occur in association with spinal cord injury, multiple sclerosis,
    cerebral palsy (see Spastic Diplegia), anoxic brain damage, brain trauma,
    severe head injury, some metabolic diseases such as adrenoleukodystrophy,
    and phenylketonuria.

    <h4>Treatment:</h4>
    If the cause is fatigue, the first remedy is rest. Massage seems to not be
    helpful. Stretching or relaxing the muscles involved may also work. Chronic
    spasms can lead to shortening of the muscles and ligaments. Regular (daily)
    exercises over the years ahead of these ligaments and muscles are needed to
    prevent the pain and the crippling of movement. <br />Treatment may include
    such medications as baclofen, diazepam, dantrolene, or clonazepam; muscle
    stretching, range of motion exercises, and other physical therapy regimens
    to help prevent joint contractures (shrinkage or shortening of a muscle) and
    reduce the severity of symptoms; or surgery for tendon release or to block
    the connection between nerve and muscle, so that the muscle does not
    contract. The connection between nerve and muscle may also be blocked
    temporarily, without surgery, by injecting botulinum toxin into the muscle.
  </div>
</template>

<script>
export default {
  name: "Spasticity"
};
</script>

<style scoped></style>
