<template>
  <div class="content">
    <h2>Failed back surgery syndrome</h2>
    <hr />
    <h4>Introduction:</h4>
    Failed back surgery syndrome (also called FBSS, or failed back syndrome) is
    a misnomer, as it is not actually a syndrome - it is a very generalized term
    that is often used to describe the condition of patients who have not had a
    successful result with back surgery or spine surgery. There is no equivalent
    term for this in any other type of surgery (e.g. there is no failed cardiac
    surgery syndrome, failed knee surgery syndrome, etc).

    <h4>Causes:</h4>
    There are many reasons that a surgery may or may not work, and even with the
    best surgeon and for the best indications, spine surgery is no more than 95%
    predictive of a successful result. Spine surgery is only basically able to
    accomplish two things:
    <ol>
      <li>Decompressing a nerve root that is pinched, or</li>
      <li>Stabilizing a painful joint</li>
    </ol>
    Unfortunately, back surgery or spine surgery cannot literally cut out a
    patient's pain. It is only able to change anatomy, and an anatomical lesion
    (injury) that is a probable cause of back pain must be identified prior to
    back surgery or spine surgery. By far the number one reason back surgery is
    not effective is because the lesion that was operated on is not in fact the
    cause of the patient's pain. Some types of back surgery are far more
    predictable in terms of alleviating a patient's symptoms than others. For
    instance:
    <ul>
      <li>
        A <b>discectomy</b> (or microdiscectomy) for a lumbar disc herniation
        that is causing leg pain is a very predictable operation. However, a
        discectomy for a lumbar disc herniation that is causing lower back pain
        is far less likely to be successful.
      </li>
      <li>
        A <b>spine fusion</b> for spinal instability (e.g. spondylolisthesis) is
        a relatively predictable operation. However, a spine fusion for
        multi-level lumbar degenerative disc disease is far less likely to be
        successful in reducing a patient's pain.
      </li>
    </ul>
    Therefore, the best way to avoid a spine surgery that leads to an
    unsuccessful result is to stick to operations that have a high degree of
    success and to make sure that an anatomic lesion that is amenable to
    surgical correction is identified preoperatively.

    <h4>Treatment:</h4>
    Generally, treatment focuses on conservative measures first, such as
    rehabilitation and pain management, before another surgery is considered.
    The conservative measures may include exercise, manual therapy, spinal cord
    stimulators and/or an implanted pump to deliver pain medication. Sometimes
    further surgery is not a consideration in the patient's pain management
    future.
  </div>
</template>

<script>
export default {
  name: "NeckShoulderPain"
};
</script>

<style scoped></style>
