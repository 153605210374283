<template>
  <footer>
    <hr />
    <small>
      <address>
        <fa-icon icon="map-marker-alt" /> 4510 Medical Center Drive, Suite 211,
        McKinney TX - 75069 &nbsp;
      </address>
      |&nbsp; <fa-icon icon="phone-alt" /> (469) 541-1613 &nbsp;|&nbsp;
      <fa-icon icon="copyright" /> 2024 Pain Medics. All rights reserved.
    </small>
  </footer>
</template>

<script>
export default {
  name: "NavBar"
};
</script>

<style scoped>
footer {
  width: 80%;
  text-align: center;
  opacity: 0.7;
  float: right;
  font-size: 1.2vw;
}

address {
  display: inline-block;
  font-style: normal;
}

hr {
  width: 80%;
  opacity: 0.2;
  height: 1px;
  background-color: gray;
}

fa-icon {
  transform: scale(1.1);
  opacity: 0.2;
}
</style>
