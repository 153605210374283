<template>
  <div class="content">
    <h2>Cervical, lumbar, &#38; thoracic epidural steroid injections</h2>
    <hr />
    <h4>What is it?</h4>
    An <b>epidural injection</b> is an injection of steroid into the epidural
    space. The <b>epidural space</b> is a space located in the spine between the
    vertebrae and the dural sac, which surrounds the spinal cord.

    <h4>Why is it performed?</h4>
    The steroid injected reduces the inflammation and/or swelling of nerves in
    the epidural space. This may in turn reduce pain, tingling, numbness, and
    other symptoms caused by nerve inflammation/irritation or swelling.

    <h4>How is it done?</h4>
    The procedure is performed under x-ray guidance. The patient is lying on
    her/his stomach. The blood pressure, heart rate, and blood oxygenation are
    monitored. The skin is cleaned with antiseptic solution. The local
    anesthetic is used to numb the skin and then the injection is performed.
  </div>
</template>

<script>
export default {
  name: "EpiduralInjections"
};
</script>

<style scoped></style>
