<template>
  <div class="content">
    <h2>Trigeminal ganglion block</h2>
    <hr />
    <h4>What is it?</h4>
    The <b>trigeminal nerve</b> is the nerve that supplies feeling and movement
    in the face. The <b>ganglion</b> are the surrounding nerve cells.

    <h4>Why is it performed?</h4>
    A block is used when the trigeminal nerve causes extreme pain and muscle
    spasms in the face and the pain occurs from:
    <ul>
      <li>Trigeminal neuralgial</li>
      <li>Cluster headache</li>
      <li>Cancer related pain</li>
      <li>Atypical facial pain</li>
    </ul>

    <h4>How is it done?</h4>
    You will be asked to lie on your back on the x-ray table. The side of the
    face where the pain is felt is cleaned with a special solution called
    <b>betadine</b>. Next, a local anesthesia of lidocaine will be given to numb
    the area. Once the area is numb a needle is inserted through the skin, which
    is directed under x-ray until it reaches the trigeminal ganglion. A local
    anesthetic and steroid will then be injected after making sure that the
    needle is not in a blood vessel or cerebrospinal fluid (CSF).
  </div>
</template>

<script>
export default {
  name: "TrigeminalBlock"
};
</script>

<style scoped></style>
