<template>
  <div class="content">
    <h2>Intrathecal pump implantation</h2>
    <hr />
    <h4>What is it?</h4>
    An <b>intrathecal pump</b> (spinal morphine pump) is a specialized device
    which delivers concentrated amounts of medication(s) into the spinal cord
    area via a small catheter (tubing).

    <h4>Why is it performed?</h4>
    This device delivers concentrated amounts of medication into the spinal cord
    area, allowing the patient to decrease or eliminate the need for oral
    medications. It delivers medication around the clock, thus eliminating or
    minimizing breakthrough pain and/or other symptoms.

    <h4>How is it done?</h4>
    It is done with the patient lying on the side. Sometimes the tubing is
    placed with the patient sitting up. The patients are monitored with an EKG,
    blood pressure cuff and blood oxygen-monitoring device. The skin is cleaned
    with antiseptic solution and then the procedure is carried out. X-ray
    (fluoroscopy) is used to guide the needle for inserting the tubing.
  </div>
</template>

<script>
export default {
  name: "IntrathecalPump"
};
</script>

<style scoped></style>
