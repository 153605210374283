<template>
  <div class="content">
    <h2>Coccydynia</h2>
    <hr />
    <h4>Introduction:</h4>
    Coccydynia, or tailbone pain, is a fairly rare and poorly understood
    condition that can cause persistent low back pain. It is felt as a localized
    pain at the very bottom of the spine (the <i>coccyx</i>) and will generally
    feel worse when sitting. <br />The condition is much more common in women
    than men. It is usually caused by local trauma (a fall) or giving birth. On
    rare occasions, an infection or tumor can also cause pain in the coccyx.
    <br />Inflammation of the bony area (tailbone or coccyx) located between the
    buttocks is referred to as coccydynia. Coccydynia is associated with pain
    and tenderness at the tip of the tailbone between the buttocks.

    <h4>Causes:</h4>
    Coccydynia is often caused by an injury, but may occur seemingly
    spontaneously. There are many causes of tailbone pain which can mimic
    coccydynia, including sciatica, infection, pilonidal cysts, and fractured
    bone.

    <h4>Treatment:</h4>
    Patients with coccydynia are advised to use a well-padded seat when sitting
    and avoid long periods of sitting as possible. If the condition becomes
    severe or persistently troublesome, then medical attention should be sought
    to accurately evaluate the cause of the pain. <br />Rest, avoiding re-injury
    to the affected area, anti-inflammation and pain medications can relieve
    symptoms. Some patients with persistent coccydynia are treated with local
    cortisone injection. This injection is simply performed in the doctor's
    office and can potentially dramatically relieve the pain and even resolve
    the symptoms for many. Rarely, when patients have unrelenting pain, a
    surgical resection of the coccyx can be performed to remove the irritated
    bony prominence.
  </div>
</template>

<script>
export default {
  name: "Coccydynia"
};
</script>

<style scoped></style>
