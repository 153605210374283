<template>
  <div class="content">
    <h2>Map</h2>
    <hr />
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3340.078296203871!2d-96.64055908451259!3d33.15957128085825!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864c1168be0e27d5%3A0x86b888b1eebcbe4e!2s4510%20Medical%20Center%20Dr%20STE%20211%2C%20McKinney%2C%20TX%2075069!5e0!3m2!1sen!2sus!4v1674874130095!5m2!1sen!2sus"
      allowfullscreen
    ></iframe>
  </div>
</template>

<script>
export default {
  name: "Map"
};
</script>

<style scoped>
.content {
  font-size: 1.2vw;
}

h4 {
  font-size: 1.5vw;
}

iframe {
  width: 100%;
  height: 27vw;
  border: 0;
  display: inline-block;
}
</style>
