<template>
  <div class="content">
    <h2>Download Forms</h2>
    <hr />
    All forms are printable as well as fillable electronically. To save a form
    filled out electronically, use your browser or PDF editor's 'Print' option
    and either save the form to a PDF or print to the printer of your choice.
    <h4>Patients</h4>
    <ul>
      <li>
        <a href="./forms/NewPatientEvaluation_20230127.pdf" target="_blank"
          >New Patient Evaluation</a
        >
      </li>
      <li>
        <a href="./forms/FollowUpConsultationForm_20230127.pdf" target="_blank"
          >Follow-Up Consultation</a
        >
      </li>
      <li>
        <a href="./forms/NarcoticPrescribingPolicy_20230127.pdf" target="_blank"
          >Narcotic Prescribing Policy</a
        >
      </li>
    </ul>
    <h4>Physicians</h4>
    <ul>
      <li>
        <a href="./forms/ConsultationRequest_20230127.pdf" target="_blank"
          >Consultation Request</a
        >
      </li>
    </ul>
    <h4>General</h4>
    <ul>
      <li>
        <a href="./forms/PrivacyPolicy_20230127.pdf" target="_blank"
          >Privacy Policy</a
        >
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Forms"
};
</script>

<style scoped>
.content {
  font-size: 1.2vw;
}

h4 {
  font-size: 1.5vw;
}

ul li {
  margin: 0.5em 0;
}
</style>
