<template>
  <div class="content">
    <h2>Costochondral junction pain</h2>
    <hr />
    <h4>Introduction:</h4>
    Costochondritis is a common form of inflammation of the cartilage where ribs
    attach to the breast bone, the sternum. The inflammation can involve
    multiple cartilage areas on both sides of the sternum but usually is on one
    side only.

    <h4>Causes:</h4>
    The cause(s) of costochondritis is(are) not known and may involve several
    factors. Possible causes include heredity (genetic predisposition), viruses,
    and trauma (injury). Costochondritis can be an independent condition by
    itself or sometimes be a feature of a more widespread disorder. Examples of
    illnesses that can feature costochondritis include fibromyalgia, psoriatic
    arthritis, ankylosing spondylitis, reactive arthritis, and inflammatory
    bowel disease (such as ulcerative colitis and Crohn's disease. <br />Common
    symptoms of costochondritis may include:
    <ul>
      <li>
        Sudden, severe pain and soreness in the chest, usually around the
        breastbone.
      </li>
      <li>
        Pain that increases when pressure is applied to a specific area of the
        breastbone where the cartilage connects the ribs.
      </li>
      <li>
        Pain that increases with coughing, sneezing, or deep breathing, all of
        which move the cartilage or the ribs.
      </li>
    </ul>
    Therefore, the best way to avoid a spine surgery that leads to an
    unsuccessful result is to stick to operations that have a high degree of
    success and to make sure that an anatomic lesion that is amenable to
    surgical correction is identified preoperatively.

    <h4>Treatment:</h4>
    Costochondritis can be aggravated by any activity that involves stressing
    the structures of the front of the chest cage. It is generally best to
    minimize these activities until the inflammation of the rib and cartilage
    areas has subsided. Rest, anti-inflammatory drugs, physical therapy, and
    even cortisone injections have been used as therapy for the inflamed,
    painful cartilage of costochondritis.
  </div>
</template>

<script>
export default {
  name: "CostochondralPain"
};
</script>

<style scoped></style>
