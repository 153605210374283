import EducationList from "./EducationList";
import BackPain from "./Conditions/BackPain";
import BackSurgery from "./Conditions/BackSurgery";
import Coccydynia from "./Conditions/Coccydynia";
import CostochondralPain from "./Conditions/CostochondralPain";
import NeckShoulderPain from "./Conditions/NeckShoulderPain";
import CentralPain from "./Conditions/CentralPain";
import ComplexRegionalPain from "./Conditions/ComplexRegionalPain";
import HerpeticNeuralgia from "./Conditions/HerpeticNeuralgia";
import PunctureHeadache from "./Conditions/PunctureHeadache";
import Spasticity from "./Conditions/Spasticity";
import PelvicPain from "./Conditions/PelvicPain";
import AmputationPain from "./Conditions/AmputationPain";
import TrigeminalNeuralgia from "./Conditions/TrigeminalNeuralgia";
import ArthriticPain from "./Conditions/ArthriticPain";
import OccipitalHeadaches from "./Conditions/OccipitalHeadaches";
import CervicogenicHeadaches from "./Conditions/CervicogenicHeadaches";
import RootBlocks from "./Modalities/RootBlocks";
import SacroiliacInjections from "./Modalities/SacroiliacInjections";
import FacetInjections from "./Modalities/FacetInjections";
import MedialBlocks from "./Modalities/MedialBlocks";
import PeripheralBlocks from "./Modalities/PeripheralBlocks";
import TriggerInjections from "./Modalities/TriggerInjections";
import RadiofrequencyLesioning from "./Modalities/RadiofrequencyLesioning";
import Discography from "./Modalities/Discography";
import SpinalStimulation from "./Modalities/SpinalStimulation";
import IntrathecalPump from "./Modalities/IntrathecalPump";
import CeliacBlock from "./Modalities/CeliacBlock";
import ImparBlock from "./Modalities/ImparBlock";
import TrigeminalBlock from "./Modalities/TrigeminalBlock";
import LumbarBlock from "./Modalities/LumbarBlock";
import StellateBlock from "./Modalities/StellateBlock";
import EpiduralInjections from "./Modalities/EpiduralInjections";

export default {
  EducationList,
  BackPain,
  BackSurgery,
  Coccydynia,
  CostochondralPain,
  NeckShoulderPain,
  CentralPain,
  ComplexRegionalPain,
  HerpeticNeuralgia,
  PunctureHeadache,
  Spasticity,
  PelvicPain,
  AmputationPain,
  TrigeminalNeuralgia,
  ArthriticPain,
  OccipitalHeadaches,
  CervicogenicHeadaches,
  RootBlocks,
  SacroiliacInjections,
  FacetInjections,
  MedialBlocks,
  PeripheralBlocks,
  TriggerInjections,
  RadiofrequencyLesioning,
  Discography,
  SpinalStimulation,
  IntrathecalPump,
  CeliacBlock,
  ImparBlock,
  TrigeminalBlock,
  LumbarBlock,
  StellateBlock,
  EpiduralInjections
};
