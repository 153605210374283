<template>
  <div class="content">
    <h2>Frequently Asked Questions (FAQs) About Pain and Pain Management</h2>
    <hr />
    <b>Q:</b> What is pain management?
    <br />
    <b>A:</b> Pain is a complex medical problem that can have profound effects
    on your physical and mental well-being. The goal of pain management is to
    help you decrease your level of pain and suffering, to return you to your
    maximum level of functioning and independence, and to help you restore your
    quality of life.
    <br />
    <br />
    <b>Q:</b> What medications are most commonly used to manage pain?
    <br />
    <b>A:</b> While drug therapies differ for each person, the most common are:
    <br />
    <ul>
      <li>
        Adjuvant pain medications, including: antidepressants, anticonvulsants,
        and muscle relaxors.
      </li>
      <li>
        Opiates or "pain killers" used to treat acute pain or cancer-related
        pain, and often prescribed for chronic pain.
      </li>
      <li>
        Anti-inflammatory drugs to alleviate pain by reducing swelling and
        irritation.
      </li>
    </ul>
    There are alternative delivery methods for medications. Common methods used
    at the office are oral medications, topical creams, sublingual medicines,
    nasal sprays, injections and patches.
    <br />
    <br />
    <b>Q:</b> What is the difference between acute and chronic pain?
    <br />
    <b>A:</b> Acute pain is of short duration, usually the result of an injury,
    surgery or illness. Chronic pain is an ongoing condition, often in the back,
    neck, head, as wells as neuropathic pain (nerve injury pain),
    musculoskeletal pain, and pain related to illness. Your physicianmay refer
    you to the Pain Management Center because your chronic pain condition has
    not responded to conventional therapies. Treatments for acute and chronic
    pain are generally quite different. In some cases, acute and chronic pain
    can be stopped or alleviated by a single procedure or series of procedures.
    Sometimes, chronic pain is part of a widespread disease process, and the
    specific cause may be difficult to pinpoint. Once we have identified the
    specific factor causing the pain, we may be able to treat it so that the
    condition no longer occurs. In some patients, the specific factor causing
    the pain--such as cancer--cannot be changed, but we may be able to reduce
    the pain or help the patient to better cope with the pain through a
    combination of medical, psychosocial and rehabilitation techniques.
    <br />
    <br />
    <b>Q:</b> When should a person seek a pain management specialist?
    <br />
    <b>A:</b> Seek out a pain management specialist when pain does not respond
    to the usual and customary treatments within a reasonable period of time.
    All too often, people see pain management as a last resort for pain, instead
    of a first stop on the road to wellness. Be aware of your body and take note
    when you are in pain. If that pain persists contact your doctor or an
    accredited pain management specialist immediately.
    <br />
    <br />
    <b>Q:</b> How can I tell my doctor how much pain I am in?
    <br />
    <b>A:</b> The best way is to describe your pain as clearly and in as much
    detail as you possibly can. Most doctors and nurses ask you to describe your
    level of pain on a scale.
    <br />
    <br />
    <b>Q:</b> Will I become addicted to my pain medication?
    <br />
    <b>A:</b> A drug addiction is not usually associated with pain medication.
    Addictions are more likely to occur when you are using a drug to get "high"
    rather than to relieve pain. Your doctors will monitor the dosage of your
    prescription to make sure you are not taking an amount that will become
    addictive. The purpose of taking pain medication is for the beneficial
    effects it produces. If you take your medicine as directed, you should not
    have any problems.
    <br />
    <br />
    <b>Q:</b> My friends and family want to help. What can they do?
    <br />
    <b>A:</b> There is a lot that your friends and family can do to make your
    life easier. They can run errands for you, or help you around the house.
    They can help you complete your journal and keep written records of your
    pain and your medications. They can help you with your prescribed exercises
    or physical therapy.
  </div>
</template>

<script>
export default {
  name: "Home"
};
</script>

<style scoped>
.content {
  font-size: 1.2vw;
}
</style>
