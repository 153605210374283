<template>
  <div>
    <router-view />
  </div>
</template>

<script>
export default {
  name: "PatientEducation"
};
</script>

<style scoped>
/deep/ .content {
  font-size: 1vw;
}

/deep/ h4 {
  font-size: 1.5vw;
  margin-bottom: 0.6vw;
}

/deep/ h5 {
  font-size: 1.2vw;
  margin: 0.5em;
  margin-left: 0;
}
</style>
