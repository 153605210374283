<template>
  <div class="content">
    <h2>Refractory pelvic pain</h2>
    <hr />
    <h4>Introduction:</h4>
    Refractory pelvic pain refers to any pain in your pelvic region (the area
    below your bellybutton and between your hips) that lasts six months or
    longer. If you were asked to locate your pain, you'd be more likely to sweep
    your hand over that entire area rather than point to one spot. Chronic
    pelvic pain can be a symptom of another disease, or it can be designated as
    a condition in its own right. <br />Determining what's causing your
    discomfort may be one of medicine's more puzzling and frustrating endeavors.
    Indeed, no physical cause may ever be discovered. Many women who experience
    chronic pelvic pain never receive a more specific diagnosis. <br />If your
    doctor can determine the source of your chronic pelvic pain, then treatment
    can focus on eliminating that cause. If no cause can be found, then
    treatment for chronic pelvic pain focuses on managing the pain.

    <h4>Causes:</h4>
    Several gynecologic problems may be the source of chronic pelvic pain.
    However, other diseases can cause pelvic pain, such as irritable bowel
    syndrome and interstitial cystitis. In addition, psychological factors may
    contribute to your pain. <br />Some of the more common causes of chronic
    pelvic pain include:
    <ul>
      <li>
        <h5>Psychological factors</h5>
        If you are depressed, experience chronic stress or have been sexually or
        physically abused, you may be more likely to experience chronic pelvic
        pain. Emotional distress makes pain worse, and likewise living with
        chronic pain makes emotional distress worse. So chronic pain and
        emotional distress frequently get locked into a vicious cycle.
      </li>
      <li>
        <h5>Interstitial cystitis</h5>
        Chronic inflammation of the bladder and a frequent need to urinate
        characterize interstitial cystitis. You may experience pelvic pain as
        your bladder fills, which may improve temporarily after emptying your
        bladder.
      </li>
      <li>
        <h5>Irritable bowel syndrome</h5>
        Symptoms associated with irritable bowel syndrome (bloating,
        constipation or diarrhea) can be a source of uncomfortable pelvic pain
        and pressure.
      </li>
      <li>
        <h5>Fibroids</h5>
        These noncancerous uterine growths may cause pressure or a feeling of
        heaviness in your lower abdomen. They rarely cause sharp (acute) pain
        unless they become deprived of nutrients and begin to die (degenerate).
      </li>
      <li>
        <h5>Ovarian remnant</h5>
        During a complete hysterectomy (surgical removal of the uterus and
        ovaries) a small piece of ovary may be left inside, which can later
        develop tiny, painful cysts.
      </li>
      <li>
        <h5>Pelvic congestion syndrome</h5>
        This is a condition that may be caused by enlarged, varicose-type veins
        around the uterus and ovaries.
      </li>
      <li>
        <h5>Chronic pelvic inflammatory disease</h5>
        This can occur if a long-term infection, often sexually transmitted,
        causes scarring involving the pelvic organs.
      </li>
      <li>
        <h5>Tension in your pelvic floor muscles</h5>
        Spasms or tension of the pelvic floor muscles can lead to recurring
        pelvic pain.
      </li>
      <li>
        <h5>Endometriosis</h5>
        This is a condition in which tissue from your uterine lining
        (endometrium) grows outside your uterus. These deposits of tissue
        respond to your menstrual cycle, just as your uterine lining does ï¿½
        thickening, breaking down and bleeding each month as your hormone levels
        rise and fall. Because it's happening outside your uterus, the blood and
        tissue can't exit your body through your vagina and become trapped in
        your abdomen. This can lead to painful cysts and adhesions (fibrous
        bands of scar tissue).
      </li>
    </ul>

    <h4>Treatment:</h4>
    Treatment for chronic pelvic pain is based upon an individualized plan and
    may need to be performed over a period of time. Some treatment options
    include:
    <ul>
      <li>
        Pain medications that provide relief temporary relief of pain while
        other therapies are tried. These may be used in combination with other
        medications like anti-depressants or anti-inflammatory drugs.
      </li>
      <li>
        Physical therapy may be used to strengthen and stretch certain muscles.
        Muscle or nerve stimulators may be used to help with muscle retraining
        or to block pain sensations. Biofeedback, relaxation and breathing
        exercises may also be useful.
      </li>
      <li>
        Psychological evaluation and therapy may be part of a comprehensive
        treatment plan.
      </li>
      <li>
        Family education to help them understand the nature of the patient's
        problem, the treatment plan, and possible outcomes may also contribute
        to the patient's recovery.
      </li>
      <li>
        Surgery may be necessary in some cases to evaluate pain, to remove
        pelvic adhesions or endometriosis.
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "PelvicPain"
};
</script>

<style scoped></style>
